export class Guid {
  static validator = new RegExp(
    '^[a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12}$',
    'i'
  );

  static EMPTY = '00000000-0000-0000-0000-000000000000';

  static isGuid(guid: any) {
    const value: string = guid.toString();
    return guid && (guid instanceof Guid || Guid.validator.test(value));
  }

  static create(): Guid {
    return new Guid(
      [Guid.gen(2), Guid.gen(1), Guid.gen(1), Guid.gen(1), Guid.gen(3)].join(
        '-'
      )
    );
  }

  static createEmpty(): Guid {
    return new Guid('emptyguid');
  }

  static parse(guid: string): Guid {
    return new Guid(guid);
  }

  static raw(): string {
    return [
      Guid.gen(2),
      Guid.gen(1),
      Guid.gen(1),
      Guid.gen(1),
      Guid.gen(3),
    ].join('-');
  }

  private static gen(count: number) {
    let out: string = '';
    for (let i: number = 0; i < count; i++) {
      out += (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    }
    return out;
  }

  private value: string;

  private constructor(guid: string) {
    if (!guid) {
      throw new TypeError('Invalid argument; `value` has no value.');
    }

    this.value = Guid.EMPTY;

    if (guid && Guid.isGuid(guid)) {
      this.value = guid;
    }
  }

  equals(other: Guid): boolean {
    // Comparing string `value` against provided `guid` will auto-call
    // toString on `guid` for comparison
    return Guid.isGuid(other) && this.value === other.toString();
  }

  isEmpty(): boolean {
    return this.value === Guid.EMPTY;
  }

  toString(): string {
    return this.value;
  }

  toJSON(): any {
    return {
      value: this.value,
    };
  }
}
