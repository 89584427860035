import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { TagModule } from 'primeng/tag';

@Component({
  selector: 'outa-works-grid-tag',
  imports: [CommonModule, TagModule],
  standalone: true,
  template: `
    <div class="flex gap-2">
      <ng-container *ngFor="let tag of params.tags">
        <p-tag
          severity="info"
          [rounded]="true"
          [value]="tag[params.tagValueField]"
        ></p-tag>
      </ng-container>
    </div>
  `,
})
export class GridTagComponent implements ICellRendererAngularComp {
  @Input() params!: any;

  agInit(params: ICellRendererParams) {
    this.params = params;
  }

  refresh() {
    return false;
  }
}
