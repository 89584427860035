import { Directive, Input, TemplateRef } from '@angular/core';

@Directive({
  selector: '[viaTemplate]',
})
export class TemplateDirective {
  @Input('viaTemplate') name?: string;

  constructor(public template: TemplateRef<any>) {}

  getType() {
    return this.name!;
  }
}
