import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { CardModule } from 'primeng/card';
import { TabViewModule } from 'primeng/tabview';

import { MenuTabItems } from '../../models/common';
import { BadgeModule } from 'primeng/badge';

@Component({
  selector: 'outa-works-tabs',
  standalone: true,
  templateUrl: './tabs.component.html',
  styleUrls: [],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TabViewModule,
    CardModule,
    BadgeModule,
  ],
})
export class TabsComponent {
  @Input() menuTabs: MenuTabItems[] = [];
  @Input() isTransparentBG = false;
  @Input() relativeToParent = false;
  @Input() activeIndex: number = 0;
  @Output() onChange: EventEmitter<number> = new EventEmitter<number>();

  constructor() {
    if (history.state?.navId === 0 || history.state?.navId) {
      this.activeIndex = history.state?.navId;
    } else {
      this.activeIndex = 0;
    }
  }

  getChild(activatedRoute: ActivatedRoute): ActivatedRoute {
    if (activatedRoute.firstChild) {
      return this.getChild(activatedRoute.firstChild);
    } else {
      return activatedRoute;
    }
  }
  onChangeIndex(index: number) {
    this.onChange.emit(index);
  }
}
