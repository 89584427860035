import { NgModule } from '@angular/core';

import { SharedGlobalModule } from '../../shared-global.module';

import { SidebarComponent } from './sidebar.component';

@NgModule({
  declarations: [SidebarComponent],
  imports: [SharedGlobalModule],
  exports: [SidebarComponent],
})
export class OSidebarModule {}
