<div
  class="mb-2 flex justify-content-between"
  *ngIf="!gridCustomOptions.gridTopbarHide"
>
  <div class="flex gap-4">
    <div *ngIf="gridCustomOptions.showActions">
      <p-splitButton
        label="Actions"
        styleClass="p-button-outlined"
        [model]="actionMenuItems"
      ></p-splitButton>
    </div>
    <ng-content select="[grid-header]"></ng-content>
    <ng-container *ngIf="actions">
      <ng-container *ngTemplateOutlet="actions"> </ng-container>
    </ng-container>
    <p-button
      *ngIf="gridInfoLines?.length"
      [rounded]="true"
      [outlined]="true"
      icon="pi pi-info"
      (mouseenter)="op.show($event)"
      (mouseleave)="op.hide()"
    ></p-button>
    <p-overlayPanel styleClass="w-5" #op appendTo="body">
      <div class="font-bold text-lg mb-3 w-full">Grid Info</div>
      <ul>
        <li *ngFor="let line of gridInfoLines">
          {{ line }}
        </li>
      </ul>
    </p-overlayPanel>
  </div>
  <div class="flex gap-4">
    <ng-container *ngIf="topCenter">
      <ng-container *ngTemplateOutlet="topCenter"> </ng-container>
    </ng-container>
    <div class="p-input-icon-left" *ngIf="gridCustomOptions.showTextSearch">
      <i class="pi pi-search text-sm"></i>
      <input
        pTooltip="Ctrl + F"
        tooltipPosition="top"
        id="search-input"
        pInputText
        #searchInput
        type="text"
        placeholder="Search keyword"
        [ngModel]="searchStr"
        (input)="searchFilter($event)"
      />
    </div>

    <p-button
      label="Clear"
      [outlined]="true"
      icon="pi pi-filter-slash"
      (click)="clearFilters()"
    ></p-button>
  </div>
</div>
<ag-grid-angular
  class="ag-theme-alpine custom-grid"
  [rowData]="rowData"
  [columnDefs]="columnDefs"
  [context]="componentParent"
  [defaultColDef]="defaultColDef"
  [suppressMenuHide]="true"
  [gridOptions]="gridOptions"
  [overlayNoRowsTemplate]="
    gridCustomOptions.noRowsTemplate || 'No Rows To Display'
  "
  (gridReady)="onGridReady($event)"
  (selectionChanged)="onSelectionChanged()"
></ag-grid-angular>
