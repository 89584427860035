import { CommonModule } from '@angular/common';
import {
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { LoggerService as logger } from '@outa-works/services';
import {
  GridActionsComponent,
  GridViewsPanelComponent,
  View,
} from '@outa-works/standalone';
import { StringUtils } from '@outa-works/utils';
import { AgGridModule } from 'ag-grid-angular';
import {
  ColDef,
  ColumnEvent,
  ColumnMovedEvent,
  ColumnPinnedEvent,
  ColumnResizedEvent,
  ColumnState,
  GetContextMenuItemsParams,
  GetRowIdParams,
  GridApi,
  GridOptions,
  GridReadyEvent,
  ICellRenderer,
  MenuItemDef,
  PaginationChangedEvent,
  RowGroupingDisplayType,
} from 'ag-grid-community';
import { NgxSpinnerService } from 'ngx-spinner';
import { MenuItem } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { SplitButtonModule } from 'primeng/splitbutton';
import {
  debounceTime,
  distinctUntilChanged,
  Subject,
  Subscription,
} from 'rxjs';

import 'ag-grid-enterprise';
type UserConfig = {
  columnState?: ColumnState[];
  pageSize?: PageSize;
};
export type PageSize = 10 | 15 | 20 | 50 | 100;

export type GridCustomOptions = {
  rowSelection?: 'single' | 'multiple'; // undefined for no row selection
  showTextSearch?: boolean;
  showActions?: boolean;
  noRowsTemplate?: string;
  loadingTemplate?: string;
  floatingFilterHide?: boolean;
  gridTopbarHide?: boolean;
  suppressPaginationPanel?: boolean;
  pageSize?: PageSize;
  excludeExcelColumns?: string[];
  savePageConfig?: { gridName: string };
  detailCellRenderer?: ICellRenderer;
  detailRowAutoHeight?: boolean;
  alwaysShowVerticalScroll?: boolean;
  groupDisplayType?: RowGroupingDisplayType;
  groupRemoveSingleChildren?: boolean;
  excludeContextMenuItems?: string[];
  getRowId?: (params: GetRowIdParams) => string;
};

@Component({
  selector: 'outa-works-grid',
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    AgGridModule,
    ButtonModule,
    DropdownModule,
    SplitButtonModule,
    InputTextModule,
    OverlayPanelModule,
    CardModule,
  ],
  standalone: true,
  templateUrl: './grid.component.html',
  styleUrls: ['./grid.component.scss'],
})
export class GridComponent<T, ComponentParent>
  implements OnInit, OnChanges, OnDestroy
{
  gridApi!: GridApi<T>;
  searchStr = '';
  userConfig: { [gridName: string]: UserConfig } = {};
  @ViewChild('searchInput') searchInput!: ElementRef;

  private readonly searchSubject = new Subject<string>();
  private searchSubscription?: Subscription;
  private spinnerSubscription?: Subscription;

  @Input() rowData: T[] = [];
  @Input() actionMenuItems: MenuItem[] = [];
  @Input() columnDefs: ColDef[] = [];
  @Input() defaultColDef: ColDef = {
    sortable: true,
    filter: true,
    resizable: true,
    flex: 1,
    floatingFilter: false,
    enablePivot: true,
    enableRowGroup: true,
    useValueFormatterForExport: true,
  };
  @Input() componentParent!: ComponentParent; // Parent component context
  @Input() gridOptions: GridOptions = {
    rowHeight: 35,
    headerHeight: 35,
    rowSelection: 'single',
    accentedSort: true,
    enableRangeSelection: true,
    pagination: true,
    paginationPageSizeSelector: [10, 15, 20, 50, 100],
    sideBar: {
      defaultToolPanel: undefined,
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
          toolPanelParams: {
            // TODO: disable pivot option
            // suppressPivotMode: true,
            // suppressPivots: true,
          },
        },
        'filters',
        // {
        //   id: 'views',
        //   labelDefault: 'Views',
        //   labelKey: 'views',
        //   iconKey: 'group',
        //   width: 300,
        //   minWidth: 300,
        //   toolPanel: GridViewsPanelComponent,
        //   toolPanelParams: {
        //     onViewSelection: (view: View) => this.onViewSelection(view),
        //   },
        // },
      ],
    },
    onColumnPinned: (params: ColumnPinnedEvent) =>
      this.checkSaveUserConfig(params),
    onColumnResized: (params: ColumnResizedEvent) =>
      this.checkSaveUserConfig(params),
    onColumnMoved: (params: ColumnMovedEvent) =>
      this.checkSaveUserConfig(params),
    onPaginationChanged: (event: PaginationChangedEvent) => {
      if (event.newPageSize) {
        this.pageSizeChanged.emit(
          event.api.paginationGetPageSize() as PageSize
        );
      }
    },
    getContextMenuItems: (params) => this.getContextMenuItems(params),
    quickFilterParser: (quickFilter) => [quickFilter],
  };
  @Input() gridCustomOptions: GridCustomOptions = {
    showTextSearch: false,
    showActions: false,
    floatingFilterHide: false,
    gridTopbarHide: false,
    pageSize: 20,
    excludeExcelColumns: [],
  };
  @Input() gridInfoLines: string[] = [];

  @Output() gridReadyEvent = new EventEmitter<GridReadyEvent<T>>();
  @Output() selectionChangeEvent = new EventEmitter<T[]>();
  @Output() pageSizeChanged = new EventEmitter<PageSize>();

  @ContentChild('actions') actions: TemplateRef<any> | undefined;
  @ContentChild('topCenter') topCenter: TemplateRef<any> | undefined;

  get IsPageConfigSaved() {
    return (
      Object.keys(
        this.userConfig[
          this.gridCustomOptions.savePageConfig?.gridName as string
        ] || {}
      ).length !== 0
    );
  }

  constructor(
    private spinnerService: NgxSpinnerService,
    private stringUtils: StringUtils
  ) {}

  ngOnInit() {
    this.initialize();
    window.addEventListener('keydown', this.handleGlobalKeyPress.bind(this));

    this.searchSubscription = this.searchSubject
      .pipe(debounceTime(400), distinctUntilChanged())
      .subscribe((searchStr) => {
        this.gridApi.setGridOption('quickFilterText', searchStr);
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes['gridOptions'] ||
      changes['gridCustomOptions'] ||
      changes['columnDefs']
    ) {
      this.initialize();
    }
  }

  ngOnDestroy() {
    this.searchSubscription?.unsubscribe();
    this.spinnerSubscription?.unsubscribe();
    window.removeEventListener('keydown', this.handleGlobalKeyPress.bind(this));
  }

  onGridReady(event: GridReadyEvent) {
    this.gridApi = event.api;
    this.gridReadyEvent.emit(event);
    this.gridApi.autoSizeAllColumns();
    this.spinnerSubscription = this.spinnerService.spinnerObservable
      .pipe(debounceTime(200), distinctUntilChanged())
      .subscribe((spinner) => {
        if (spinner.show) {
          this.gridApi.hideOverlay();
        } else if (!this.rowData.length) {
          this.gridApi.showNoRowsOverlay();
        }
      });

    if (this.gridCustomOptions.savePageConfig) {
      setTimeout(() => this.loadUserConfig(), 1000);
    }
  }

  initialize() {
    this.gridOptions = {
      ...this.gridOptions,
      rowSelection: this.gridCustomOptions.rowSelection,
      defaultExcelExportParams: {
        sheetName: 'data',
        columnKeys: this.columnDefs.reduce((acc: string[], v: ColDef) => {
          if (
            v.field !== 'actions' &&
            !this.gridCustomOptions.excludeExcelColumns?.includes(v.field || '')
          ) {
            return v.field ? [...acc, v.field] : acc;
          }
          return acc;
        }, []),
      },
      paginationPageSize: this.gridCustomOptions.pageSize || 20,
      suppressPaginationPanel:
        this.gridCustomOptions.suppressPaginationPanel || false,
      groupDisplayType: this.gridCustomOptions.groupDisplayType,
      groupRemoveSingleChildren:
        this.gridCustomOptions.groupRemoveSingleChildren || false,
      getRowId: this.gridCustomOptions.getRowId,
    };

    if (
      this.gridCustomOptions.rowSelection &&
      this.gridCustomOptions.rowSelection === 'multiple'
    ) {
      this.defaultColDef = {
        ...this.defaultColDef,
        headerCheckboxSelectionFilteredOnly: true,
      };
      this.columnDefs[0] = {
        ...this.columnDefs[0],
        headerCheckboxSelection: true,
        checkboxSelection: true,
        showDisabledCheckboxes: true,
      };
    }

    this.setColumnConfig();

    if (this.gridCustomOptions.floatingFilterHide) {
      this.defaultColDef.floatingFilter =
        this.gridCustomOptions.floatingFilterHide;
      this.defaultColDef.menuTabs = [];
    }

    if (this.gridCustomOptions.detailCellRenderer) {
      this.setMasterDetailGridConfig();
    }
  }

  onSelectionChanged() {
    const selectedRows = this.gridApi.getSelectedRows();
    this.selectionChangeEvent.emit(selectedRows);
  }

  searchFilter(event: Event) {
    const searchQuery = (event.target as HTMLInputElement).value || '';
    this.searchStr = searchQuery;
    this.searchSubject.next(searchQuery.trim());
  }

  handleGlobalKeyPress(event: KeyboardEvent): void {
    if (event.ctrlKey && event.key === 'f') {
      event.preventDefault();
      this.searchInput.nativeElement.focus();
    } else if (event.key === 'Escape') {
      this.clearFilters();
      this.searchInput.nativeElement.blur();
    }
  }

  clearFilters() {
    this.searchStr = '';
    this.searchSubject.next('');
    this.gridApi.setGridOption('quickFilterText', '');
    this.gridApi.setFilterModel(null);
  }

  getContextMenuItems(
    params: GetContextMenuItemsParams
  ): (string | MenuItemDef)[] {
    let defaultMenuItems = params.defaultItems || [
      'cut',
      'copy',
      'copyWithHeaders',
      'copyWithGroupHeaders',
      'paste',
      'export',
    ];
    const customMenuItems = [
      {
        name: 'Enable Config Saving',
        action: () => {
          if (this.IsPageConfigSaved) {
            this.deleteUserConfig();
          } else {
            this.saveUserConfig();
          }
        },
        checked: this.IsPageConfigSaved,
        cssClasses: ['font-semibold'],
      },
    ];

    if (this.gridCustomOptions.excludeContextMenuItems) {
      defaultMenuItems = defaultMenuItems.filter(
        (d) => !this.gridCustomOptions.excludeContextMenuItems?.includes(d)
      );
    }

    return !this.gridCustomOptions.savePageConfig?.gridName
      ? defaultMenuItems
      : [...defaultMenuItems, ...customMenuItems];
  }

  setColumnConfig() {
    this.columnDefs = this.columnDefs.map((col) =>
      col.field === 'actions'
        ? {
            ...col,
            headerName: '',
            minWidth: 50,
            maxWidth: 50,
            cellRenderer: GridActionsComponent,
            cellClass: 'flex align-items-center justify-content-center',
            pinned: 'right',
            filter: false,
            sortable: false,
          }
        : {
            ...col,
            headerTooltip:
              col.headerTooltip ??
              this.stringUtils.camelCaseToSpacePascalCase(
                col.headerName || col.field || ''
              ),
          }
    );
  }

  setMasterDetailGridConfig() {
    this.gridOptions = {
      ...this.gridOptions,
      masterDetail: true,
      detailCellRenderer: this.gridCustomOptions.detailCellRenderer,
      detailRowAutoHeight: this.gridCustomOptions.detailRowAutoHeight || true,
      alwaysShowVerticalScroll:
        this.gridCustomOptions.alwaysShowVerticalScroll || true,
    };
  }

  checkSaveUserConfig(params: ColumnEvent) {
    if (
      this.IsPageConfigSaved &&
      params.source !== 'api' &&
      params.source !== 'flex' &&
      params.source !== 'rowDataUpdated'
    ) {
      this.saveUserConfig();
    }
  }

  deleteUserConfig() {
    this.userConfig[this.gridCustomOptions.savePageConfig?.gridName as string] =
      {};
    localStorage.setItem('agGridUserConfig', JSON.stringify(this.userConfig));
  }

  saveUserConfig() {
    const gridName = this.gridCustomOptions.savePageConfig?.gridName as string;
    const columnState = this.gridApi.getColumnState();
    const pageSize = this.gridApi.paginationGetPageSize() as PageSize; // Type assertion here

    this.userConfig[gridName] = {
      columnState,
      pageSize,
    };

    localStorage.setItem('agGridUserConfig', JSON.stringify(this.userConfig));
  }

  loadUserConfig() {
    const storedConfig = localStorage.getItem('agGridUserConfig');
    if (storedConfig) {
      this.userConfig = JSON.parse(storedConfig);
      const gridName = this.gridCustomOptions.savePageConfig
        ?.gridName as string;

      if (this.userConfig[gridName]?.columnState) {
        this.gridApi.applyColumnState({
          state: this.userConfig[gridName].columnState,
          applyOrder: true,
        });
      }

      if (this.userConfig[gridName]?.pageSize) {
        this.gridApi.paginationSetPageSize(this.userConfig[gridName].pageSize);
      }
    }
  }
}
