import { Injectable } from '@angular/core';
import { GpsQuestion } from '@outa-works/models';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GuidedProcessStepService {
  private showGuidedProcessSteps = false;
  private inputQuestions: GpsQuestion[] = [];
  private outputAnswers = new BehaviorSubject<any>(null);
  outputAnswers$ = this.outputAnswers.asObservable();

  set InputQuestions(val: GpsQuestion[]) {
    this.inputQuestions = val;
  }

  get InputQuestions() {
    return this.inputQuestions;
  }

  set OutputAnswers(value: { [key: string]: any }) {
    this.outputAnswers.next(value);
  }

  get ShowGuidedProcessSteps() {
    return this.showGuidedProcessSteps;
  }

  toggleVgpsView(shouldShow: boolean) {
    this.showGuidedProcessSteps = shouldShow;
  }
}
