<div class="w-full">
  <p-tabView
    [(activeIndex)]="activeIndex"
    [ngClass]="{ 'transparent-nav': isTransparentBG }"
    (activeIndexChange)="onChangeIndex($event)"
  >
    <ng-container *ngIf="menuTabs.length">
      <p-tabPanel
        [disabled]="tab.isDisabled ?? false"
        [leftIcon]="tab.icon"
        [header]="tab.tabLabel"
        *ngFor="let tab of menuTabs"
      >
        <ng-template pTemplate="header">
          <span class="flex items-center">
            <i class="mr-2 text-sm" [ngClass]="tab.icon"></i>
            <span>{{ tab.tabLabel }}</span>
          </span>
          <span *ngIf="tab.badgeCount">
            <p-badge
              class="ml-2 font-normal"
              [value]="tab.badgeCount"
            ></p-badge>
          </span>
        </ng-template>
        <ng-container></ng-container>
      </p-tabPanel>
    </ng-container>
  </p-tabView>
</div>
