import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { SelectButtonModule } from 'primeng/selectbutton';
import { TooltipModule } from 'primeng/tooltip';

import { OPanelMenuComponent } from './components/panelmenu/panelmenu.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    TooltipModule,
    SelectButtonModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  exports: [OPanelMenuComponent, RouterModule, TooltipModule],
  declarations: [OPanelMenuComponent],
})
export class OPanelMenuModule {}
