<footer class="text-center">
  <span class="partialy-visible">
    Powered by
    <a
      href="https://outamation.com/"
      target="_blank"
      class="text-primary font-medium"
      >Outamation&reg;</a
    >
  </span>
  <span class="partialy-visible"> v{{ appVersion }}</span>
</footer>
