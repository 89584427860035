import { CurrencyPipe, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';

import { DisplaySpacePipe } from './display-space.pipe';
import { EllipsisPipe } from './ellipsis.pipe';
import { EntriesPipe } from './entries.pipe';
import { KeysPipe } from './keys.pipe';
import { PascalToSpacePipe } from './pascal-to-space.pipe';
import { SafePipe } from './safe.pipe';

const INTERNAL_PIPES = [
  EllipsisPipe,
  SafePipe,
  KeysPipe,
  PascalToSpacePipe,
  DisplaySpacePipe,
  EntriesPipe,
];
const EXTERNAL_PIPES = [DatePipe, CurrencyPipe];

@NgModule({
  declarations: INTERNAL_PIPES,
  imports: [],
  exports: INTERNAL_PIPES,
  providers: [...INTERNAL_PIPES, ...EXTERNAL_PIPES],
})
export class PipesModule {}
