import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { TableModule } from 'primeng/table';
import { TooltipModule } from 'primeng/tooltip';

export type View = { id: number; name: string };

@Component({
  selector: 'outa-works-grid-views-panel',
  imports: [
    CommonModule,
    FormsModule,
    InputTextModule,
    ButtonModule,
    TableModule,
    TooltipModule,
    OverlayPanelModule,
  ],
  standalone: true,
  templateUrl: './views-panel.component.html',
  styleUrls: ['./views-panel.component.scss'],
})
export class GridViewsPanelComponent implements ICellRendererAngularComp {
  params: any;
  viewName = '';
  views: View[] = [
    { id: 1, name: 'Default View' },
    { id: 2, name: 'view 2' },
  ];
  viewTableColumns = [{ header: 'Name', field: 'name' }];

  agInit(params: ICellRendererParams) {
    this.params = params;
  }

  refresh() {
    return false;
  }

  onViewSelection(event: View) {
    if (event) {
      this.params.onViewSelection(event);
    }
  }

  createView() {
    this.views.push({ id: this.views.length + 1, name: this.viewName });
    this.viewName = '';
  }

  deleteView(id: number) {
    this.views = this.views.filter((v) => v.id !== id);
  }
}
