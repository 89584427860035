import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { loginGuard } from './shared-global/guards/login.guard';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'app',
  },
  {
    matcher: (segments) => {
      const regexPattern = /^code.*$/i;
      const [firstSegment] = segments;
      const isMatch = regexPattern.test(firstSegment.path);
      return isMatch ? { consumed: segments } : null;
    },
    redirectTo: 'app',
  },
  {
    path: 'app',
    canActivate: [loginGuard],
    loadChildren: () =>
      import('./layout/layout.module').then((m) => m.LayoutModule),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: true,
      paramsInheritanceStrategy: 'always',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
