import { Injectable } from '@angular/core';
import { AnyObject, DynamicField } from '@outa-works/models';
import { ApiService } from 'src/app/services/api.service';

export type GroupedFields = { [group: string]: DynamicField[] };

@Injectable({
  providedIn: 'root',
})
export class DataverseService {
  private _dataverse: DynamicField[] = [];
  private _groups: string[] = [];
  private _fields: GroupedFields = {};
  private _fieldObject: AnyObject<DynamicField> = {};

  get fields() {
    if (Object.keys(this._fields).length) {
      return this._fields;
    }
    return this.getDataverse().then(() => this._fields);
  }

  get fieldObject() {
    if (this._dataverse.length) {
      return this._fieldObject;
    }
    return this.getDataverse().then(() => this._fieldObject);
  }

  get groups() {
    if (this._groups.length) {
      return this._groups;
    }
    return this.getDataverse().then(() => this._groups);
  }

  constructor(private apiService: ApiService) {}

  async getDataverse() {
    if (!this._dataverse.length) {
      this._dataverse =
        await this.apiService.get<DynamicField[]>('api/dataverse');
    }
    this.buildFieldGroups();

    return this._dataverse;
  }

  buildFieldGroups() {
    this._groups = Array.from(
      new Set(this._dataverse.map((field) => field.group || 'Misc'))
    );

    this._fields = this._dataverse.reduce((acc: GroupedFields, field) => {
      const groupName = field.group || 'Misc';
      return {
        ...acc,
        [groupName]: acc[groupName] ? [...acc[groupName], field] : [field],
      };
    }, {});

    this._fieldObject = this._dataverse.reduce(
      (acc: AnyObject<DynamicField>, field) => ({
        ...acc,
        [field.uid]: field,
      }),
      {}
    );
  }
}
