import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { Router } from '@angular/router';

import { StepperService } from './stepper.service';

export interface Stepper {
  title: string;
  subTitle: string;
  icon: string;
  iconType: 'primeng' | 'material' | 'img';
  routerLink: string;
}

@Component({
  selector: 'outa-works-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class StepperComponent implements OnInit, OnDestroy {
  @Input() stepperData!: Stepper[];

  @Output() stepperIndex = new EventEmitter<number>();

  constructor(
    private router: Router,
    public stepperService: StepperService
  ) {}

  ngOnInit() {
    this.stepperService.StepperData = this.stepperData;
    this.stepperService.ActiveIndex = this.stepperData.findIndex(
      (step) => this.router.url === step.routerLink
    );

    if (this.stepperService.ActiveIndex !== 0) {
      this.stepperService.ActiveIndex = 0;
      // this.router.navigate([this.stepperData[0].routerLink]);
    }
  }

  ngOnDestroy() {
    this.stepperService.resetStepper();
  }

  onStepperClick(stepIndex: number) {
    // TODO: Remove return after dynamic route urls have been fixed for stepper
    return;
    if (
      stepIndex < this.stepperService.ActiveIndex ||
      stepIndex <= this.stepperService.MaxStepCompleted
    ) {
      this.stepperService.ActiveIndex = stepIndex;
      this.router.navigate([this.stepperData[stepIndex].routerLink]);
    }
  }
}
