import { NgModule } from '@angular/core';

import { CapitalizeDirective } from './capitalize.directive';
import { TemplateDirective } from './template.directive';
import { TrimDirective } from './trim.directive';

const INTERNAL_DIRECTIVES = [
  TrimDirective,
  TemplateDirective,
  CapitalizeDirective,
];

@NgModule({
  declarations: INTERNAL_DIRECTIVES,
  imports: [],
  exports: INTERNAL_DIRECTIVES,
  providers: INTERNAL_DIRECTIVES,
})
export class DirectivesModule {}
