import { Injectable } from '@angular/core';
import dayjs, { type Dayjs, ManipulateType, OpUnitType } from 'dayjs';
import DayJSTimezone from 'dayjs/plugin/timezone';
import DayJSUtc from 'dayjs/plugin/utc';

dayjs.extend(DayJSUtc);
dayjs.extend(DayJSTimezone);

@Injectable({
  providedIn: 'root',
})
export class DateService {
  private dayjs = dayjs;

  /**
   * @link https://github.com/date-fns/date-fns/blob/main/src/isDate
   */
  private isDate(value: unknown): value is Date {
    return (
      value instanceof Date ||
      (typeof value === 'object' &&
        Object.prototype.toString.call(value) === '[object Date]')
    );
  }

  /**
   * @link https://github.com/date-fns/date-fns/blob/main/src/toDate
   */
  private toDate<DateType extends Date>(
    argument: DateType | number | string
  ): DateType {
    const argStr = Object.prototype.toString.call(argument);

    // Clone the date
    if (
      argument instanceof Date ||
      (typeof argument === 'object' && argStr === '[object Date]')
    ) {
      return new (argument.constructor as any)(+argument);
    } else if (
      typeof argument === 'number' ||
      argStr === '[object Number]' ||
      typeof argument === 'string' ||
      argStr === '[object String]'
    ) {
      return new Date(argument) as DateType;
    } else {
      return new Date(NaN) as DateType;
    }
  }

  getDate() {
    return this.dayjs().toDate();
  }

  parse(date: string | number | Date) {
    return this.dayjs(date);
  }

  isValid(date: unknown): boolean {
    if (!this.isDate(date) && typeof date !== 'number') {
      return false;
    }
    const _date = this.toDate(date);
    return !isNaN(Number(_date));
  }

  format(date: string | number | Date, formatString: string) {
    return this.dayjs(date).format(formatString);
  }

  isSame(
    date1: string | number | Date,
    date2: string | number | Date,
    unit: OpUnitType = 'day'
  ) {
    return this.parse(date1).isSame(date2, unit);
  }

  isAfter(date: string | number | Date, unit: OpUnitType = 'day') {
    return this.dayjs().isAfter(this.parse(date), unit);
  }

  isBefore(date: string | number | Date, unit: OpUnitType = 'day') {
    return this.dayjs().isBefore(this.parse(date), unit);
  }

  add(
    count: number,
    unit: ManipulateType = 'day',
    date: string | number | Date = this.dayjs().toDate()
  ) {
    return this.parse(date).add(count, unit);
  }

  subtract(
    count: number,
    unit: ManipulateType = 'day',
    date: string | number | Date = this.dayjs().toDate()
  ) {
    return this.parse(date).subtract(count, unit);
  }

  calculateDateDifference(
    startDate: string | number | Date,
    endDate: string | number | Date,
    unit: ManipulateType = 'day'
  ) {
    return this.parse(endDate).diff(this.parse(startDate), unit);
  }

  addUtcOffset(date: Dayjs, unit: ManipulateType = 'minute') {
    return date.add(date.utcOffset(), unit);
  }
}
