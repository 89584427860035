import { DatePipe } from '@angular/common';

export type AnyObject<
  ValueDataType,
  KeyDataType extends string | number | symbol = string,
> = Record<KeyDataType, ValueDataType>;

export type MenuTabItems = {
  id?: number;
  tabLabel: string;
  normalizedName?: string;
  navUrl: string;
  icon: string;
  isDisabled?: boolean;
  visible?: boolean;
  class?: string;
  rightIcon?: string;
  badgeCount?: any;
};

export type DropDownOptions = {
  id: number;
  label: string;
};

export type DropDownNameOptions = {
  id: number;
  name: string;
};

export type PolarOptions = {
  label: string;
  value: string | boolean | number;
};

export const YesNoOptions = [
  { label: 'Yes', value: true },
  { label: 'No', value: false },
];

type MetricsData = {
  name: string;
  count: number;
  colorId: number;
};

export type WidgetItem = {
  metrics: MetricsData[];
  title: string;
  class: string;
};

// eslint-disable-next-line arrow-body-style
export const formatPhoneNumber = (params: { value: string }) => {
  return `<i class="pi pi-phone pr-2"></i>  <a class="text-primary"  href="tel:${params.value}">${params.value}</a>`;
};

// eslint-disable-next-line arrow-body-style
export const formatEmail = (params: { value: string }) => {
  return `<i class="pi pi-envelope pr-2"></i>  <a class="text-primary" href="mailto:${params.value}">${params.value}</a>`;
};

const datePipe = new DatePipe('en-US');
export const genericDateFormat = (date: any) => {
  if (date !== null && date !== undefined && date !== '') {
    return datePipe.transform(date, 'MM/dd/yyyy');
  } else {
    return '';
  }
};
