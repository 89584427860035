import { computed, effect, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@environments';
import { MenuItem } from 'primeng/api';

import { PermissionAction, PermissionSubject } from '../../models/user';
import { CommonService } from '../../services/common.service';
import { UserDataService } from '../../services/user-data.service';
import { AuthService } from 'src/app/services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class SidebarService {
  // disabling because this is just one big array of items and cannot be broken down further
  // eslint-disable-next-line max-lines-per-function
  public menuItems = computed(() => [
    {
      label: 'Dashboard',
      icon: 'pi pi-th-large',
      visible: true,
      routerLink: '/app/dashboard',
      disabled: false,
      tooltipOptions: {
        tooltipLabel: 'Dashboard',
      },
    },
    {
      label: this.authService.userRoleName().includes('AGENT')
        ? 'My Work Queue'
        : 'Work Queue',
      icon: 'pi pi-list',
      visible: true,
      expanded: false,
      routerLink: '/app/patient-encounters/work-queue',
      tooltipOptions: {
        tooltipLabel: this.authService.userRoleName().includes('AGENT')
          ? 'My Work Queue'
          : 'Work Queue',
      },
    },
    {
      label: 'Outamate Works',
      icon: 'pi pi-fw pi-bars',
      visible: false,
      expanded: false,
      items: [
        {
          label: 'Workflow Components',
          routerLink: 'app/workflow-management/workflows',
          visible: true,
        },
        {
          label: 'Outamate Works Designer',
          target: '_blank',
          routerLink: environment.WORKFLOW_URL,
          visible: true,
        },
      ],
      tooltipOptions: {
        tooltipLabel: 'Outamate Works',
      },
    },
    {
      label: 'Reports',
      icon: 'pi pi-file',
      visible: true,
      routerLink: '/app/reports',
      tooltipOptions: {
        tooltipLabel: 'Reports',
      },
    },
    {
      label: 'User Management',
      icon: 'pi pi-users',
      visible:
        this.authService.userRoleName().includes('SUPER_ADMIN') ||
        this.authService.userRoleName().includes('MANAGER'),
      routerLink: '/app/user-management',
      tooltipOptions: {
        tooltipLabel: 'User Management',
      },
    },
  ]);
  selectedMenu: any;

  constructor(
    public commonService: CommonService,
    private router: Router,
    private authService: AuthService
  ) {
    effect(
      () => {
        this.getActiveMenu();
      },
      { allowSignalWrites: true }
    );
  }

  getActiveMenu(parentIndex: number = -1, items?: MenuItem[]) {
    if (!items) {
      this.selectedMenu = null;
      items = this.menuItems();
    }
    for (let i = 0; i < items.length; i++) {
      const menu = items[i];
      if (this.selectedMenu) {
        return;
      }
      if (menu.routerLink && this.router.url.includes(menu.routerLink)) {
        this.selectedMenu =
          this.menuItems()[parentIndex === -1 ? i : parentIndex];
        return;
      }

      if (menu.items && menu.items.length) {
        this.getActiveMenu(i, menu.items);
      }
    }
  }
}
