/* eslint-disable simple-import-sort/imports */
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// PrimeNG Imports
import { ConfirmationService, MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';

// Other Imports
import { environment } from '@environments';
import { AppRoutingModule } from './app-routing.module';
import { SharedGlobalModule } from './shared-global/shared-global.module';
import { OSidebarModule } from './shared-global/modules/sidebar/sidebar.module';
import { AppComponent } from './app.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { AccessRequiredComponent } from './access-required/access-required.component';
import { AbilityModule } from '@casl/angular';
import { CommonHeadersInterceptor } from './shared-global/interceptors/common-headers.interceptor';

// const isIE =
//   window.navigator.userAgent.indexOf('MSIE ') > -1 ||
//   window.navigator.userAgent.indexOf('Trident/') > -1; // Remove this line to use Angular Universal

@NgModule({
  declarations: [AppComponent, PageNotFoundComponent, AccessRequiredComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    SharedGlobalModule,
    OSidebarModule,
    ToastModule,
    AbilityModule,
  ],
  providers: [
    Title,
    MessageService,
    ConfirmationService,
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CommonHeadersInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
