/// <reference types="@angular/localize" />

import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { environment } from '@environments';

import { AppModule } from './app/app.module';
import { LoggerService as logger } from './app/shared-global/services/logger.service';

platformBrowserDynamic([
  {
    provide: 'BASE_URL',
    useFactory: () => environment.BASE_URL,
    deps: [],
  },
])
  .bootstrapModule(AppModule)
  .catch((err) => logger.error(err));
