<aside
  class="dockable-sidebar dockable-sidebar--{{ opened ? 'opened' : 'closed' }}"
  class="collapsable-sidebar"
>
  <div
    class="flex justify-content-end p-2 cursor-pointer white-space-nowrap"
    (click)="collapseSideBar()"
  >
    <i class="pi pi-angle-double-left text-white"> </i>
  </div>
  <div class="text-white mt-3 text-base" *ngIf="menuItem?.items">
    <div class="px-3 white-space-nowrap">{{ menuItem.label }}</div>
    <div class="p-3">
      <p-selectButton
        [options]="commonService.getActivatedMenuItems(menuItem.items)"
        [(ngModel)]="selectedSubMenuItem"
        id="panel-sub-item"
        (onOptionClick)="onSubMenuItemClick($event)"
      >
        <ng-template let-item>
          {{ item.label }}
        </ng-template>
      </p-selectButton>
    </div>
  </div>
</aside>
