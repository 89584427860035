import { Location } from '@angular/common';
import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ButtonModule } from 'primeng/button';

@Component({
  selector: 'outa-works-coming-soon',
  standalone: true,
  imports: [RouterModule, ButtonModule],
  template: `
    <div class="grid-center h-full">
      <div class="flex flex-column justify-content-center gap-3">
        <img
          src="assets/images/coming-soon.svg"
          alt="coming-soon"
          width="150"
          height="150"
        />
        <span class="text-2xl font-medium color-primary">
          The Page is under development!
        </span>
        <div class="flex justify-content-center gap-2">
          <p-button
            icon="pi pi-arrow-left"
            iconPos="left"
            label="Back"
            (click)="back()"
          ></p-button>
          <p-button
            icon="pi pi-home"
            iconPos="left"
            label="Home"
            routerLink="/"
          ></p-button>
        </div>
      </div>
    </div>
  `,
})
export class ComingSoonComponent {
  constructor(private location: Location) {}

  back() {
    this.location.back();
  }
}
