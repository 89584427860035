<p-toast
  key="app-toast"
  position="bottom-left"
  [breakpoints]="{ '9999px': { left: '4.5rem' } }"
></p-toast>
<ngx-spinner
  type="ball-scale-multiple"
  bdColor="rgba(51,51,51,0)"
  [template]="spinnerTemplate"
>
</ngx-spinner>
<div class="app-container">
  <router-outlet></router-outlet>
</div>
