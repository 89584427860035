<div class="custom-step" [ngClass]="{ active: activeIndex === stepIndex }">
  <div
    *ngIf="stepData.iconType === 'primeng'"
    class="step-icon"
    [ngClass]="{
      disabled: !(stepIndex < activeIndex || stepIndex <= maxStepCompleted)
    }"
  >
    <i class="fs-5" [class]="stepData.icon"></i>
  </div>
  <div *ngIf="stepData.iconType === 'material'" class="step-icon material">
    <span class="material-icons">{{ stepData.icon }}</span>
  </div>
  <div *ngIf="stepData.iconType === 'img'" class="step-icon svg">
    <img [src]="stepData.icon" class="svg-img" />
  </div>

  <div class="step-text">
    <span class="desc">{{ stepData.title }}</span>
    <span
      class="sub-desc"
      [pTooltip]="stepData.subTitle"
      tooltipPosition="bottom"
    >
      {{ stepData.subTitle | ellipsis : 20 }}
    </span>
  </div>
  <i class="pi pi-angle-right right-arrow"></i>
</div>
