import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { environment } from '@environments';
import { map } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';

import { UserInfo } from '../models/user';

export const loginGuard: CanActivateFn = () => {
  const router = inject(Router);
  const authService = inject(AuthService);

  return authService.Authenticated().pipe(
    map((response: UserInfo) => {
      if (response.userName) {
        return true;
      } else {
        router.navigate([environment.BASE_URL]);
        return false;
      }
    })
  );
};
