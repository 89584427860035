// eslint-disable-next-line @typescript-eslint/no-var-requires, prefer-template
import packageJson from '../../package.json';
const appVersion = packageJson.version + '-dev';
const domainName = 'OUTAMATION';

export const environment = {
  production: false,
  LOG_LEVEL: 'WARN',
  AG_GRID_LICENSE_KEY:
    '[TRIAL]_this_AG_Grid_Enterprise_key_( AG-043118 )_is_granted_for_evaluation_only___Use_in_production_is_not_permitted___Please_report_misuse_to_( legal@ag-grid.com )___For_help_with_purchasing_a_production_key_please_contact_( info@ag-grid.com )___All_Front-End_JavaScript_developers_working_on_the_application_would_need_to_be_licensed___This_key_will_deactivate_on_( 31 July 2023 )____[v2]_MTY5MDc1ODAwMDAwMA==f7deb9985cb10bc1921d8a43ac3c1b44',
  BASE_URL: 'https://dev-oomcwq.outamationlabs.com/core/',
  REDIRECT_URL: '/outa-works-ui/',
  appVersion,
  WORKFLOW_URL: 'https://dev-oomcwq.outamationlabs.com/works-ui/',
  domainName,
};
