<p-card>
  <ng-template pTemplate="content">
    @if (filter) {
      <div class="px-2">
        <span class="p-input-icon-right w-full">
          <i class="pi pi-search"></i>
          <input
            type="text"
            pInputText
            class="w-full"
            [ngModel]="searchStr"
            (input)="searchFilter($event)"
          />
        </span>
      </div>
    }

    <div class="listbox-container">
      @if (ShowEmptyFilterMessage) {
        <span class="font-semibold px-2">{{ emptyFilterMessage }}</span>
      } @else {
        @for (option of Options; track $index) {
          <span
            class="listbox-item"
            [ngClass]="{
              highlight:
                (optionValue ? $any(option)[optionValue] : option) === value
            }"
            (click)="option !== value && onOptionClicked(option)"
          >
            @if (itemTemplate) {
              <ng-container
                *ngTemplateOutlet="itemTemplate; context: { $implicit: option }"
              ></ng-container>
            } @else {
              {{ $any(option)[optionLabel] }}
            }
          </span>
        }
      }
    </div>
  </ng-template>
</p-card>
