import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

import { CommonService } from '../services/common.service';

@Component({
  selector: 'outa-works-referral-header',
  imports: [CommonModule],
  template: `
    <span class="outa-works-referral-header">
      <span class="cursor-pointer" (click)="copyCaseNumber()">
        <span [ngClass]="{ 'text-base': size === 'small' }"
          >Encounter Number:
        </span>
        <span
          [ngClass]="{ 'text-lg': size === 'small' }"
          class="color-primary font-medium"
        >
          {{ encNumber }} - {{ encStatus }}
          {{ isRejected ? ' (Already Rejected)' : '' }}
        </span>
      </span>
    </span>
  `,
  styles: [
    `
      .outa-works-referral-header {
        display: block;
        font-size: 1.2rem;
        font-weight: 700;
      }
    `,
  ],
  standalone: true,
})
export class OReferralHeaderComponent {
  @Input() encNumber!: string;
  @Input() encStatus!: string;
  @Input() isRejected = false;
  @Input() size: 'small' | 'large' = 'large';

  constructor(private commonService: CommonService) {}

  copyCaseNumber() {
    navigator.clipboard.writeText(this.encNumber);
    this.commonService.showToast(
      'Success',
      'Encounter Number copied to clipboard!'
    );
  }
}
