<div class="grid-center h-full">
  <div class="flex flex-column justify-content-center gap-3">
    <img
      src="assets/images/access-required.svg"
      alt="access-required"
      width="150"
      height="150"
    />
    <span class="text-3xl font-bold color-primary">Access Required</span>
    <span class="text-2xl font-medium color-primary">
      Sorry, your request could not be processed.
    </span>
    <div class="flex justify-content-center gap-2">
      <button
        pButton
        pRipple
        type="button"
        icon="pi pi-arrow-left"
        iconPos="left"
        label="Back"
        (click)="back()"
      ></button>
      <button
        pButton
        pRipple
        type="button"
        icon="pi pi-home"
        iconPos="left"
        label="Home"
        routerLink="/"
      ></button>
    </div>
  </div>
</div>
