import { MongoAbility } from '@casl/ability';

export interface ProfileType {
  givenName?: string;
  surname?: string;
  userPrincipalName?: string;
  id?: string;
  displayName?: string;
  shortName?: string;
  outaWorksUserId?: number;
}

export interface UserInfo {
  id: number;
  userName?: string;
  name: string;
  roleId: number;
  role: string;
}

export enum PermissionAction {
  Create = 'Create',
  Add = 'Add',
  Read = 'Read',
  Edit = 'Edit',
  Delete = 'Delete',
  Remove = 'Remove',
  View = 'View',
  Active = 'Active',
  Inactive = 'Inactive',
  AddNote = 'AddNote',
  AddDocumnent = 'AddDocument',
  Upload = 'Upload',
  Download = 'Download',
  AddTask = 'AddTask',
  Complete = 'Complete',
  NotNeeded = 'NotNeeded',
  Generate = 'Generate',
  AddParticipant = 'AddParticipant',
  AddUser = 'AddUser',
  SetasActive = 'SetasActive',
  SetasInactive = 'SetasInactive',
  AddDepartment = 'AddDepartment',
  AddEmployee = 'AddEmployee',
  AddTemplate = 'AddTemplate',
  AddWorkFlow = 'AddWorkFlow',
  Design = 'Design',
  CreateaCopy = 'CreateaCopy',
  AddSubProcess = 'AddSubProcess',
  AddStep = 'AddStep',
  SetDefaultValues = 'SetDefaultValues',
  AddVariable = 'AddVariable',
  ViewRequest = 'ViewRequest',
  ViewResponse = 'ViewResponse',
  DownloadRequest = 'DownloadRequest',
  DownloadResponse = 'DownloadResponse',
  ChangeLog = 'ChangeLog',
  BulkBlock = 'BulkBlock',
  BulkUnblock = 'BulkUnblock',
  ActiveInactive = 'ActiveInactive',
  AddClient = 'AddClient',
  AddTenant = 'AddTenant',
  BulkActive = 'BulkActive',
  BulkInactive = 'BulkInactive',
  Export = 'Export',
}

export enum PermissionSubject {
  // modules
  outaWorksCore = 'outaWorksCore',
  outaWorksCMS = 'outaWorksCMS',
  outaWorksWorks = 'outaWorksWorks',
  outaWorksAdmin = 'outaWorksAdmin',
  outaWorksPeople = 'outaWorksPeople',

  MyWorkQueue = 'outaWorksCMS.Dashboard.MyWorkQueue',
  DashboardMyTasks = 'outaWorksCMS.Dashboard.MyTasks',
  MyCalendar = 'outaWorksCMS.Dashboard.MyCalendar',
  MyWorkQueues = 'outaWorksCMS.MyWorkQueues',
  MyTasks = 'outaWorksCMS.MyTasks',
  Case = 'outaWorksCMS.Case',
  ReferralIntake = 'outaWorksCMS.ReferralIntake',
  CaseSearch = 'outaWorksCMS.CaseSearch',
  CaseSteps = 'outaWorksCMS.Case.CaseSteps',
  Notes = 'outaWorksCMS.Case.Notes',
  Documents = 'outaWorksCMS.Case.Documents',
  CaseTasks = 'outaWorksCMS.Case.Tasks',
  Participants = 'outaWorksCMS.Case.Participants',
  ManageParticipants = 'outaWorksCMS.ManageParticipants',
  CaseDesignation = 'outaWorksCMS.ManageParticipants.CaseDesignation',
  Client = 'outaWorksCMS.ManageParticipants.Client',
  CourtDesignation = 'outaWorksCMS.ManageParticipants.CourtDesignation',
  Vendor = 'outaWorksCMS.ManageParticipants.Vendor',
  Plaintiff = 'outaWorksCMS.ManageParticipants.Plaintiff',
  ManageUser = 'outaWorksPeople.ManageUser',
  Dashboard = 'outaWorksPeople.Dashboard',
  DashboardTasks = 'outaWorksPeople.Dashboard.Tasks',
  DashboardQueue = 'outaWorksPeople.Dashboard.Queue',
  DashboardMetrics = 'outaWorksPeople.Dashboard.Metrics',
  DashboardInventoryLog = 'outaWorksPeople.Dashboard.InventoryLog',
  ManageDepartment = 'outaWorksPeople.ManageDepartment',
  EmployeeManagement = 'outaWorksPeople.EmployeeManagement',
  SystemAccess = 'outaWorksPeople.SystemAccess',
  WorkflowComponents = 'outaWorksWorks.WorkflowComponents',
  Designer = 'outaWorksWorks.Designer',
  WorkflowLibrary = 'outaWorksWorks.WorkflowComponents.WorkflowLibrary',
  SubProcessLibrary = 'outaWorksWorks.WorkflowComponents.SubProcessLibrary',
  StepLibrary = 'outaWorksWorks.WorkflowComponents.StepLibrary',
  VariableLibrary = 'outaWorksWorks.VariableLibrary',
  ChecklistLibrary = 'outaWorksWorks.ChecklistLibrary',
  ManageClient = 'outaWorksAdmin.ManageClient',
  ClientManagement = 'outaWorksAdmin.ClientManagement',
  ManageTenant = 'outaWorksAdmin.ManageTenant',
  ManageSelectList = 'outaWorksAdmin.ManageSelectList',
}

export type AppAbility = MongoAbility<[PermissionAction, PermissionSubject]>;

export interface AccessibleTenant {
  tenantId: string;
  name: string;
  isCurrentTenant: boolean;
  tenantType: string;
  tenantTypeId: number;
}

export interface UserPermission {
  id: number;
  name: string;
  code: string;
  type?: string;
  moduleId?: number;
  moduleName?: string;
}

export interface UserRole {
  roleId: number;
  userId: number;
  roleName: string;
  permissions: UserPermission[];
}

export interface PermissionModule {
  id: number;
  name: string;
  code: PermissionSubject;
}

export interface UserDetails {
  id: number;
  uniqueId: string;
  email: string;
  firstName: string;
  middleName: any;
  lastName: string;
  isActive: boolean;
  currentTenantId: string;
  accessibleTenants: AccessibleTenant[];
  userRoles: UserRole[];
  modules: PermissionModule[];
}

export type UserDetailsSummary = {
  id: number;
  email: string;
  name: string;
  firstName: string;
  middleName: any;
  lastName: string;
  phoneNumber: any;
  isActive: boolean;
  tenantIds: string[];
  currentTenantName: string;
  accessibleTenantNames: string;
  currentTenantId: string;
};

export type SecurityTemplate = {
  id: number;
  name: string;
  tenantId: string;
  tenantName: string;
  isEditable: boolean;
  statusId: number;
  roleStatus: string;
  numberOfUsers: number;
  permissions: UserPermission[];
};
