import { Directive, HostListener, Input } from '@angular/core';
import { FormControlName } from '@angular/forms';

@Directive({
  selector: 'input[viaCapitalize], textarea[viaCapitalize]',
})
export class CapitalizeDirective {
  // Note: string type allows us to directly use directive in HTML without providing explicit value
  @Input('viaCapitalize') active?: string | boolean = true;

  constructor(private formControlName: FormControlName) {}

  @HostListener('input')
  onInput() {
    if (
      this.active ||
      (typeof this.active === 'string' &&
        (!this.active || Boolean(this.active)))
    ) {
      this.formControlName.control.patchValue(
        this.formControlName.control.value?.toUpperCase()
      );
    }
  }
}
