import { CommonModule } from '@angular/common';
import {
  AfterContentInit,
  Component,
  ContentChildren,
  EventEmitter,
  forwardRef,
  Input,
  Output,
  QueryList,
  TemplateRef,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormsModule,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
} from '@angular/forms';
import { CardModule } from 'primeng/card';
import { InputTextModule } from 'primeng/inputtext';

import { TemplateDirective } from '../../directives';

@Component({
  selector: 'outa-works-listbox',
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    CardModule,
    InputTextModule,
  ],
  standalone: true,
  templateUrl: './listbox.component.html',
  styleUrls: ['./listbox.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      // eslint-disable-next-line no-use-before-define
      useExisting: forwardRef(() => ListboxComponent),
      multi: true,
    },
  ],
})
export class ListboxComponent<T>
  implements ControlValueAccessor, AfterContentInit
{
  protected itemTemplate!: TemplateRef<any> | undefined;
  protected searchStr = '';

  @Input() value!: T | T[keyof T];
  @Input() options: T[] = [];
  @Input() optionLabel = 'label';
  @Input() optionValue?: string;
  @Input() filter = false;
  @Input() emptyFilterMessage = 'No records found';

  @Output() optionChangeEvent = new EventEmitter<T | T[keyof T]>();

  @ContentChildren(TemplateDirective)
  viaTemplates?: QueryList<TemplateDirective>;

  get Options() {
    return this.searchStr
      ? this.options.filter((opt) =>
          (opt[this.optionLabel as keyof T] as string)
            .toLowerCase()
            .includes(this.searchStr)
        )
      : this.options;
  }

  get ShowEmptyFilterMessage() {
    return (
      this.searchStr &&
      !this.options.filter((opt) =>
        (opt[this.optionLabel as keyof T] as string)
          .toLowerCase()
          .includes(this.searchStr)
      ).length
    );
  }

  ngAfterContentInit() {
    this.viaTemplates?.forEach((item) => {
      switch (item.getType()) {
        case 'item':
          this.itemTemplate = item.template;
          break;
        default:
          this.itemTemplate = item.template;
          break;
      }
    });
  }

  onOptionClicked(option: T) {
    this.writeValue(option); // Update value depending on optionValue
    this.onChange(option); // Run change detection
    this.optionChangeEvent.emit(this.value); // Emit current value
  }

  searchFilter(event: Event) {
    const searchQuery = (event.target as HTMLInputElement).value || '';
    this.searchStr = searchQuery;
  }

  writeValue(value: any) {
    this.value =
      value && this.optionValue ? value[this.optionValue as keyof T] : value;
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onChange(_: T | T[keyof T]) {}

  onTouched() {}
}
