import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { CommonService, LoggerService as logger } from '@outa-works/services';
import { ButtonModule } from 'primeng/button';
import { TableModule } from 'primeng/table';

type FileEvent = Event & { target: { files: FileList } };

type IconPosition = 'left' | 'right' | 'top' | 'bottom';

type FileButtonOptions = {
  label: string;
  show: boolean;
  disabled: boolean;
  styleClass: string;
  icon: string;
  iconPos: IconPosition;
};

type AllowedFileTypes =
  | 'image/png'
  | 'image/tiff'
  | 'image/tif'
  | 'image/jpeg'
  | 'application/zip'
  | 'text/csv'
  | 'application/rtf'
  | 'application/pdf'
  | 'application/msword'
  | 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
  | 'application/vnd.ms-excel'
  | 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  | 'application/vnd.ms-powerpoint'
  | 'application/vnd.openxmlformats-officedocument.presentationml.presentation';

export type FileCustomOptions = {
  allowMultiple?: boolean;
  accept?: string;
  allowedFileTypes?: AllowedFileTypes[];
  choose?: Partial<FileButtonOptions>;
  upload?: Partial<FileButtonOptions>;
  clear?: Partial<FileButtonOptions>;
  dragDrop?: Partial<{
    label: string;
    hideAfterUpload: boolean;
    showImage: boolean;
  }>;
  uploadedFiles?: Partial<{
    show: boolean;
    showRemove: boolean;
  }>;
};

@Component({
  selector: 'outa-works-file-upload',
  imports: [CommonModule, ButtonModule, TableModule],
  standalone: true,
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
})
export class FileUploadComponent implements OnChanges {
  protected files: File[] = [];
  protected isValidFiles = true;
  protected dragging = false;

  private defaultFileCustomOptions: FileCustomOptions = {
    allowMultiple: false,
    accept: '',
    allowedFileTypes: undefined,
    choose: {
      label: 'Choose',
      show: true,
      disabled: false,
      styleClass: 'p-button-primary',
      icon: 'pi pi-plus',
      iconPos: 'left',
    },
    upload: {
      label: 'Upload',
      show: false,
      disabled: false,
      styleClass: 'p-button-primary',
      icon: 'pi pi-upload',
      iconPos: 'left',
    },
    clear: {
      label: 'Clear',
      show: true,
      disabled: false,
      styleClass: 'p-button-primary',
      icon: 'pi pi-times',
      iconPos: 'left',
    },
    dragDrop: {
      label: 'Drag & drop file(s) here',
      hideAfterUpload: true,
      showImage: true,
    },
    uploadedFiles: {
      show: true,
      showRemove: true,
    },
  };

  @ViewChild('fileInput') fileInput!: { nativeElement: HTMLInputElement };

  @Input() uploadedFiles: File[] = [];
  @Input() fileCustomOptions: FileCustomOptions = {};

  @Output() upload = new EventEmitter<File[]>();

  constructor(private commonService: CommonService) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['fileCustomOptions']) {
      this.fileCustomOptions = this.commonService.merge(
        this.defaultFileCustomOptions,
        changes['fileCustomOptions'].currentValue
      ) as FileCustomOptions;
    } else if (changes['uploadedFiles']) {
      this.files = this.uploadedFiles;
    }
  }

  onFileSelected(event: FileEvent) {
    const files = Array.from(event.target.files);
    if (!this.fileCustomOptions.upload?.show && this.isValid(files)) {
      this.files = files;
      this.uploadFiles();
    } else {
      this.commonService.showToast(
        'Error',
        `Invalid file type, allowed file types: ${this.fileCustomOptions.accept}`
      );
    }
  }

  choose() {
    this.fileInput.nativeElement.click();
  }

  uploadFiles() {
    logger.log(this.files);
    if (this.files.length > 0) {
      this.upload.emit(this.files);
    }
  }

  clear() {
    this.files = [];
    this.upload.next([]);
  }

  onDragOver(event: DragEvent) {
    event.preventDefault();
    this.dragging = true;

    const files = Array.from(event.dataTransfer?.items || []);
    this.isValidFiles = this.isValid(files);
  }

  onDrop(event: DragEvent) {
    event.preventDefault();
    this.dragging = false;

    this.files = Array.from(event.dataTransfer?.files || []);
    if (!this.isValid(this.files)) {
      this.files = [];
      return;
    }

    if (!this.fileCustomOptions.upload?.show) {
      this.uploadFiles();
    }
  }

  removeFile(file: File) {
    this.files = this.files.filter((f) => f !== file);
    this.upload.next(this.files);
  }

  isValid(files: (File | DataTransferItem)[]) {
    return (
      (this.fileCustomOptions.allowMultiple
        ? files.length >= 1
        : files.length === 1) &&
      files.every((f) =>
        this.fileCustomOptions.allowedFileTypes?.includes(
          f.type as AllowedFileTypes
        )
      )
    );
  }
}
