import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { StepsModule } from 'primeng/steps';

import { PipesModule } from '../../pipes/pipes.module';

import { StepComponent } from './components/step/step.component';
import { StepperComponent } from './stepper.component';

@NgModule({
  declarations: [StepperComponent, StepComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    StepsModule,
    ButtonModule,
    PipesModule,
  ],
  exports: [StepperComponent],
})
export class OStepperModule {}
