<p-table
  #viewTable
  [value]="views"
  [columns]="viewTableColumns"
  selectionMode="single"
  (selectionChange)="onViewSelection($event)"
  dataKey="id"
>
  <ng-template pTemplate="caption">
    <p-button
      label="Create View"
      styleClass="w-full"
      [outlined]="true"
      (onClick)="overlayPanel.toggle($event)"
    ></p-button>
    <p-overlayPanel #overlayPanel (onShow)="viewNameInput.focus()">
      <div class="flex gap-2">
        <input #viewNameInput type="text" pInputText [(ngModel)]="viewName" />
        <p-button
          icon="pi pi-check"
          [rounded]="true"
          [outlined]="true"
          [raised]="true"
          [disabled]="!viewName"
          (onClick)="createView()"
        ></p-button>
      </div>
    </p-overlayPanel>
  </ng-template>
  <ng-template pTemplate="header" let-columns>
    <tr>
      <th></th>
      <th>
        <div class="flex align-items-center gap-1">Name</div>
      </th>
      <th class="w-1rem"></th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-rowData let-columns="columns">
    <tr>
      <td>
        <p-tableRadioButton [value]="rowData"></p-tableRadioButton>
      </td>
      <td>{{ rowData.name }}</td>
      <td>
        <p-button
          *ngIf="rowData.id !== 1"
          icon="pi pi-times"
          [rounded]="true"
          [outlined]="true"
          [raised]="true"
          pTooltip="Delete"
          tooltipPosition="bottom"
          (onClick)="deleteView(rowData.id)"
        ></p-button>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr>
      <td colspan="7">No views created</td>
    </tr>
  </ng-template>
</p-table>
