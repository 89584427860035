import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CommonService } from '../services/common.service';

@Injectable()
export class CommonHeadersInterceptor implements HttpInterceptor {
  constructor(private commonService: CommonService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const reqCopy = req.clone({
      withCredentials: true,
    });

    return next.handle(reqCopy).pipe(
      catchError((err: HttpErrorResponse) => {
        if (err) {
          // this.commonService.showToast(
          //   'Error',
          //   err.headers.get('x-error') ?? "Couldn't perform action",
          //   'Authorization Error'
          // );
        }
        return throwError(() => err);
      })
    );
  }
}
