<div class="custom-steppers">
  <outa-works-step
    *ngFor="let step of stepperData; let i = index"
    [stepData]="step"
    [activeIndex]="stepperService.ActiveIndex"
    [maxStepCompleted]="stepperService.MaxStepCompleted"
    [stepIndex]="i"
    (click)="onStepperClick(i)"
  ></outa-works-step>
</div>
