import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { TableModule } from 'primeng/table';

interface HeaderCol {
  field: string;
  header: string;
  isSortable: boolean;
}

@Component({
  selector: 'outa-works-table-header',
  imports: [CommonModule, TableModule],
  standalone: true,
  templateUrl: './table-header.component.html',
  styles: [
    `
      :host {
        display: contents;
      }
    `,
  ],
})
export class OTableHeaderComponent {
  @Input() tableColumns!: HeaderCol[];
}
