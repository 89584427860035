import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { TooltipModule } from 'primeng/tooltip';

type ITooltipCellRendererParams = ICellRendererParams & {
  position: 'right' | 'left' | 'top' | 'bottom';
  values: {
    label: string;
    tooltip: string;
    condition?: boolean;
  }[];
};

@Component({
  selector: 'outa-works-grid-tool-tip',
  imports: [CommonModule, TooltipModule],
  standalone: true,
  template: `
    @for (item of params.values; track $index) {
      <span [pTooltip]="item.tooltip" [tooltipPosition]="params.position">
        {{ item.label }}</span
      >
      @if (!$last) {
        <span>,</span>
      }
    } @empty {
      <span>-</span>
    }
  `,
})
export class GridTooltipComponent implements ICellRendererAngularComp {
  params!: ITooltipCellRendererParams;

  agInit(params: ITooltipCellRendererParams) {
    this.params = params;
    this.params.values = this.params.values.filter(
      (v) => v.condition === undefined || v.condition === true
    );
  }

  refresh() {
    return false;
  }
}
