import { ElsaWorkflowActivity } from '../models/elsa';

// TODO: Use proper types from Elsa instead of any here
type WorkflowActivity = ElsaWorkflowActivity;
type WorkflowConnection = any;

export class WorkflowGraphNode {
  constructor(
    private value: WorkflowActivity,
    private children: Array<WorkflowGraphNode> = [],
    private parents: Array<WorkflowGraphNode> = []
  ) {}

  get Id() {
    return this.value.id;
  }

  private static validateIncomingActivitiesAndConnections(
    activitiesWithoutParents: string[],
    allowEmtpyGraph: boolean
  ) {
    if (activitiesWithoutParents.length > 1) {
      return {
        isValid: false,
        error: {
          severity: 'error',
          summary: 'Cannot get start of the workflow',
          detail: 'Multiple probable start points found',
        },
      };
    }
    if (!activitiesWithoutParents.length && !allowEmtpyGraph) {
      return {
        isValid: false,
        error: {
          severity: 'error',
          summary: 'Cannot get start of the workflow',
          detail: 'No start points found',
        },
      };
    }
    return {
      isValid: true,
    };
  }

  static fetchGraphRootAndNodes(
    activities: WorkflowActivity[],
    connections: WorkflowConnection[],
    allowEmtpyGraph = false
  ) {
    const activitiesWithoutParents = activities.reduce(
      (acc: string[], activity: ElsaWorkflowActivity) => {
        const isTargetAtleastOnce = connections.find(
          (connection) =>
            (connection.target.activity || connection.target) === activity.id
        );
        if (isTargetAtleastOnce) {
          return acc;
        }
        return [...acc, activity.id];
      },
      []
    );

    const { isValid, error } = this.validateIncomingActivitiesAndConnections(
      activitiesWithoutParents,
      allowEmtpyGraph
    );

    if (!isValid) return { error };

    const graphNodes = activities.reduce(
      (acc, activity) => ({
        ...acc,
        [activity.id]: new WorkflowGraphNode(activity),
      }),
      {}
    ) as { [key: string]: WorkflowGraphNode };
    const root = graphNodes[activitiesWithoutParents[0]];
    return {
      data: {
        graphNodes,
        root,
      },
    };
  }

  static getVisitedActivities(
    activities: WorkflowActivity[],
    visited: string[]
  ) {
    const activityMap: { [key: string]: WorkflowActivity } = activities.reduce(
      (acc, activity) => ({
        ...acc,
        [activity.id]: activity,
      }),
      {}
    );
    return visited.map((item) => activityMap[item]);
  }

  addChildren(newChildren: WorkflowGraphNode[]) {
    for (const newChild of newChildren) {
      const keyExists = this.children.find((item) => item.Id === newChild.Id);
      if (!keyExists) {
        this.children.push(newChild);
        newChild.addParent(this);
      }
    }
  }

  addParent(newParent: WorkflowGraphNode) {
    const keyExists = this.children.find((item) => item.Id === newParent.Id);
    if (!keyExists) {
      this.parents.push(newParent);
    }
  }

  buildActivitySequence(
    node: WorkflowGraphNode,
    activities: WorkflowActivity[],
    connections: WorkflowConnection[],
    graphNodes: { [key: string]: WorkflowGraphNode }
  ) {
    const children = connections.reduce((acc, connection) => {
      if (
        (connection.source.activity || connection.source) === node.Id &&
        !graphNodes[connection.target.activity || connection.target].value
          .isBuilt
      ) {
        graphNodes[
          connection.target.activity || connection.target
        ].value.isBuilt = true;
        return [
          ...acc,
          graphNodes[connection.target.activity || connection.target],
        ];
      }
      return acc;
    }, []);
    if (!children.length) {
      return;
    }
    children.map((child: WorkflowGraphNode) =>
      this.buildActivitySequence(child, activities, connections, graphNodes)
    );
    node.addChildren(children);
  }

  static depthFirstSearch(
    graphNode: WorkflowGraphNode,
    visited: Array<string> = []
  ) {
    if (graphNode.parents.length > 1) {
      const unvisitedParents = graphNode.parents.filter(
        (parent) => !visited.includes(parent.Id)
      );
      if (unvisitedParents.length) {
        const firstUnvisitedParent = WorkflowGraphNode.findFirstUnvisitedParent(
          graphNode,
          visited
        );
        WorkflowGraphNode.depthFirstSearch(firstUnvisitedParent, visited);
      }
    }

    if (visited.includes(graphNode.Id)) {
      return visited;
    }
    visited.push(graphNode.Id);
    for (const item of graphNode.children) {
      WorkflowGraphNode.depthFirstSearch(item, visited);
    }
    return visited;
  }

  static findFirstUnvisitedParent(
    graphNode: WorkflowGraphNode,
    visited: Array<string>
  ): WorkflowGraphNode {
    const unvisitedParents = graphNode.parents.filter(
      (parent) => !visited.includes(parent.Id)
    );
    if (unvisitedParents.length) {
      return WorkflowGraphNode.findFirstUnvisitedParent(
        unvisitedParents[0],
        visited
      );
    }
    return graphNode;
  }

  static breadthFirstSearch(root: WorkflowGraphNode) {
    const result: string[] = [];
    const queue = [root];
    while (queue.length > 0) {
      const current = queue.shift();
      if (current === null) continue;
      result.push(current!.value.id);
      for (const child of current?.children || []) {
        queue.push(child);
      }
    }
    return result;
  }
}
