/* eslint-disable no-console */
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

enum LogLevel {
  DEBUG = 0,
  INFO = 1,
  WARN = 2,
  ERROR = 3,
}

/**
 * @description Logger service for environment logging.
 */
@Injectable({
  providedIn: 'root',
})
export class LoggerService {
  public static debug(...message: any): void {
    LoggerService.writeToLog(LogLevel.DEBUG, ...message);
  }

  public static log(...message: any) {
    LoggerService.writeToLog(LogLevel.INFO, ...message);
  }

  public static warn(...message: any) {
    LoggerService.writeToLog(LogLevel.WARN, ...message);
  }

  public static error(...message: any) {
    LoggerService.writeToLog(LogLevel.ERROR, ...message);
  }

  /**
   * Should write the log?
   */
  private static shouldLog(level: LogLevel): boolean {
    return level >= (LogLevel[environment.LOG_LEVEL as any] as any);
  }

  /**
   * Write logs.
   */
  private static writeToLog(level: LogLevel, ...message: any) {
    if (this.shouldLog(level)) {
      if (level <= LogLevel.INFO) {
        console.log(
          '%cINFO',
          'background: green; color: white; padding: 2px; border-radius: 2px',
          ...message
        );
      } else if (level === LogLevel.ERROR) {
        console.error(
          '%cERROR',
          'background: red; color: white; padding: 2px; border-radius: 2px',
          ...message
        );
      } else if (level === LogLevel.WARN) {
        console.warn(
          '%cWARN',
          'background: yellow; color: black; padding: 2px; border-radius: 2px',
          ...message
        );
      }
    }
  }
}
