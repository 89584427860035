import { HttpClient } from '@angular/common/http';
import { Injectable, signal } from '@angular/core';
import { environment } from '@environments';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

export interface UserInfo {
  id: number;
  userName?: string;
  name: string;
  roleId: number;
  role: string;
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private http: HttpClient) {}
  private getCurrentUserRoleId?: number;
  private getCurrentUserId?: number;

  userRoleName = signal<string[]>([]);

  get Profile() {
    return this.getCurrentUserRoleId;
  }

  get UserId() {
    return this.getCurrentUserId;
  }

  Authenticated(): Observable<UserInfo> {
    return this.http
      .get<UserInfo>(`${environment.BASE_URL}api/userAuthenticated`)
      .pipe(
        tap((userInfo: UserInfo) => {
          this.getCurrentUserRoleId = userInfo.roleId;
          this.getCurrentUserId = userInfo.id;
          this.userRoleName.set(userInfo.role.split(','));
        })
      );
  }
}
