import { Directive, HostListener, Input } from '@angular/core';
import { FormControlName } from '@angular/forms';

@Directive({
  selector: 'input[viaTrim], textarea[viaTrim]',
})
export class TrimDirective {
  // Note: string type allows us to directly use directive in HTML without providing explicit value
  @Input('viaTrim') active?: string | boolean = true;

  constructor(private formControlName: FormControlName) {}

  @HostListener('blur')
  onBlur() {
    if (
      this.active ||
      (typeof this.active === 'string' &&
        (!this.active || Boolean(this.active)))
    ) {
      this.formControlName.control.patchValue(
        this.formControlName.control.value?.trim()
      );
    }
  }
}
