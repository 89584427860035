import { Component, Input, ViewEncapsulation } from '@angular/core';

import { Stepper } from '../../stepper.component';

@Component({
  selector: 'outa-works-step',
  templateUrl: './step.component.html',
  styleUrls: ['./step.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class StepComponent {
  @Input() stepData!: Stepper;
  @Input() activeIndex!: number;
  @Input() stepIndex!: number;
  @Input() maxStepCompleted!: number;
}
