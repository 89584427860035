import { Location } from '@angular/common';
import { Component } from '@angular/core';

@Component({
  selector: 'outa-works-access-required',
  templateUrl: './access-required.component.html',
})
export class AccessRequiredComponent {
  constructor(private location: Location) {}

  back() {
    this.location.back();
  }
}
