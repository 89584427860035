import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { ChipModule } from 'primeng/chip';

@Component({
  selector: 'outa-works-grid-chip',
  imports: [CommonModule, ChipModule],
  standalone: true,
  template: `
    <p-chip
      class="grid-chip white-space-nowrap"
      [attr.status]="params.label"
      [label]="params.label"
    ></p-chip>
  `,
  styleUrls: ['./chip.component.scss'],
})
export class GridChipComponent implements ICellRendererAngularComp {
  @Input() params!: any;

  agInit(params: ICellRendererParams) {
    this.params = params;
  }

  refresh() {
    return false;
  }
}
