import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { environment } from '@environments';

@Component({
  selector: 'outa-works-footer',
  imports: [CommonModule],
  standalone: true,
  templateUrl: './footer.component.html',
  styles: [
    `
      .partialy-visible {
        opacity: 0.7;
      }
    `,
  ],
})
export class FooterComponent {
  appVersion = environment.appVersion;
}
