import { Location } from '@angular/common';
import { Component } from '@angular/core';
import { CommonService } from '@outa-works/services';

@Component({
  selector: 'outa-works-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss'],
})
export class PageNotFoundComponent {
  constructor(
    private location: Location,
    public commonService: CommonService
  ) {}

  back() {
    this.location.back();
  }
}
