import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { ButtonModule } from 'primeng/button';
import { MenuModule } from 'primeng/menu';

@Component({
  selector: 'outa-works-grid-actions',
  imports: [CommonModule, ButtonModule, MenuModule],
  standalone: true,
  template: `
    <p-button
      class="flex"
      icon="pi pi-ellipsis-v"
      [rounded]="true"
      [outlined]="true"
      (click)="params.actionMenuItems && actionMenu.toggle($event)"
    ></p-button>

    <p-menu
      #actionMenu
      [model]="params.actionMenuItems || []"
      [popup]="true"
      appendTo="body"
    ></p-menu>
  `,
})
export class GridActionsComponent implements ICellRendererAngularComp {
  params: any;

  agInit(params: ICellRendererParams) {
    this.params = params;
  }

  refresh() {
    return false;
  }
}
