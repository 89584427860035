<div class="file-upload-container p-3 mb-3">
  <input
    #fileInput
    type="file"
    [accept]="fileCustomOptions.accept"
    [multiple]="fileCustomOptions.allowMultiple"
    (change)="onFileSelected($any($event))"
  />

  <div class="flex align-items-center gap-3">
    <p-button
      *ngIf="!files.length && fileCustomOptions.choose?.show"
      [label]="fileCustomOptions.choose?.label"
      [styleClass]="fileCustomOptions.choose?.styleClass"
      [icon]="fileCustomOptions.choose?.icon"
      [iconPos]="fileCustomOptions.choose?.iconPos || 'left'"
      (click)="choose()"
    ></p-button>
    <p-button
      *ngIf="fileCustomOptions.upload?.show"
      [label]="fileCustomOptions.upload?.label"
      [styleClass]="fileCustomOptions.upload?.styleClass"
      [icon]="fileCustomOptions.upload?.icon"
      [iconPos]="fileCustomOptions.upload?.iconPos || 'left'"
      [disabled]="!files.length || !!(upload | async)?.length"
      (click)="uploadFiles()"
    ></p-button>
    <p-button
      *ngIf="fileCustomOptions.clear?.show"
      [label]="fileCustomOptions.clear?.label"
      [styleClass]="fileCustomOptions.clear?.styleClass"
      [icon]="fileCustomOptions.clear?.icon"
      [iconPos]="fileCustomOptions.clear?.iconPos || 'left'"
      [disabled]="!files.length"
      (click)="clear()"
    ></p-button>
    <ng-content select="[file-headers]"></ng-content>
  </div>

  <p-table
    *ngIf="files.length && fileCustomOptions.uploadedFiles?.show"
    [value]="files"
    class="block mt-3"
  >
    <ng-template pTemplate="header">
      <tr>
        <th>Name</th>
        <th>Size</th>
        <th *ngIf="fileCustomOptions.uploadedFiles?.showRemove">Remove</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-file>
      <tr>
        <td>{{ file.name }}</td>
        <td>{{ (file.size / 1024).toFixed(2) }} KB</td>
        <td *ngIf="fileCustomOptions.uploadedFiles?.showRemove">
          <p-button
            icon="pi pi-times"
            [rounded]="true"
            [outlined]="true"
            (onClick)="removeFile(file)"
          ></p-button>
        </td>
      </tr>
    </ng-template>
  </p-table>

  <div
    *ngIf="
      !fileCustomOptions.dragDrop?.hideAfterUpload ||
      (fileCustomOptions.upload?.show
        ? !(upload | async)?.length
        : !files.length)
    "
    class="drag-drop-area flex flex-column justify-content-center align-items-center gap-3 mt-3 p-3 select-none"
    [ngClass]="dragging ? (isValidFiles ? 'valid' : 'invalid') : ''"
    (dragover)="onDragOver($event)"
    (drop)="onDrop($event)"
  >
    @if (fileCustomOptions.dragDrop?.showImage) {
      <img src="assets/images/drag-drop.svg" draggable="false" />
    }
    <span>
      {{ fileCustomOptions.dragDrop?.label }}
    </span>
    <span class="font-bold">
      (Allowed file types are: {{ fileCustomOptions.accept }})
    </span>
  </div>
</div>
