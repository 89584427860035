/* eslint-disable simple-import-sort/imports */
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

// PrimeNG Modules
import { AccordionModule } from 'primeng/accordion';
import { AvatarModule } from 'primeng/avatar';
import { BadgeModule } from 'primeng/badge';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CardModule } from 'primeng/card';
import { CarouselModule } from 'primeng/carousel';
import { ChartModule } from 'primeng/chart';
import { CheckboxModule } from 'primeng/checkbox';
import { ChipModule } from 'primeng/chip';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ContextMenuModule } from 'primeng/contextmenu';
import { DialogModule } from 'primeng/dialog';
import { DividerModule } from 'primeng/divider';
import { DropdownModule } from 'primeng/dropdown';
import { FileUploadModule } from 'primeng/fileupload';
import { InputMaskModule } from 'primeng/inputmask';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { KeyFilterModule } from 'primeng/keyfilter';
import { MenuModule } from 'primeng/menu';
import { MultiSelectModule } from 'primeng/multiselect';
import { PanelModule } from 'primeng/panel';
import { PasswordModule } from 'primeng/password';
import { ProgressBarModule } from 'primeng/progressbar';
import { RippleModule } from 'primeng/ripple';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { SelectButtonModule } from 'primeng/selectbutton';
import { SidebarModule } from 'primeng/sidebar';
import { StepsModule } from 'primeng/steps';
import { TableModule } from 'primeng/table';
import { TabMenuModule } from 'primeng/tabmenu';
import { TabViewModule } from 'primeng/tabview';
import { TooltipModule } from 'primeng/tooltip';
import { TreeTableModule } from 'primeng/treetable';
import { RadioButtonModule } from 'primeng/radiobutton';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { SplitButtonModule } from 'primeng/splitbutton';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { PickListModule } from 'primeng/picklist';
import { KnobModule } from 'primeng/knob';
import { TagModule } from 'primeng/tag';
import { InputGroupModule } from 'primeng/inputgroup';
import { TreeSelectModule } from 'primeng/treeselect';
import { OrderListModule } from 'primeng/orderlist';

// Internal Modules & Components
import { OPanelMenuModule } from './modules/panelmenu/panelmenu.module';
import { OStepperModule } from './modules/stepper/stepper.module';
import {
  OReferralHeaderComponent,
  TabsComponent,
  OTableHeaderComponent,
  GridComponent,
  FooterComponent,
  FileUploadComponent,
  ListboxComponent,
} from '@outa-works/standalone';

// Other Modules & Components
import { NgxSpinnerModule } from 'ngx-spinner';
import { PipesModule } from './pipes/pipes.module';
import { DirectivesModule } from './directives/directives.module';
import { TriStateCheckboxModule } from 'primeng/tristatecheckbox';

const ANGULAR_MODULES = [
  CommonModule,
  FormsModule,
  ReactiveFormsModule,
  RouterModule,
];

const INTERNAL_SHARED_MODULES = [OPanelMenuModule, OStepperModule];
const INTERNAL_SHARED_COMPONENTS = [
  OTableHeaderComponent,
  OReferralHeaderComponent,
  TabsComponent,
  GridComponent,
  FooterComponent,
  FileUploadComponent,
  ListboxComponent,
];

const PRIME_NG_SHARED_MODULES = [
  ButtonModule,
  AvatarModule,
  StepsModule,
  CardModule,
  InputTextModule,
  DropdownModule,
  TooltipModule,
  RippleModule,
  DialogModule,
  ConfirmDialogModule,
  CheckboxModule,
  InputTextareaModule,
  InputNumberModule,
  InputMaskModule,
  CalendarModule,
  ScrollPanelModule,
  DividerModule,
  TableModule,
  MultiSelectModule,
  ContextMenuModule,
  ProgressBarModule,
  KeyFilterModule,
  PasswordModule,
  AccordionModule,
  ChipModule,
  TreeTableModule,
  MenuModule,
  BadgeModule,
  TabMenuModule,
  TabViewModule,
  SelectButtonModule,
  ChartModule,
  FileUploadModule,
  PanelModule,
  SidebarModule,
  CarouselModule,
  InputSwitchModule,
  RadioButtonModule,
  AutoCompleteModule,
  ToggleButtonModule,
  SplitButtonModule,
  OverlayPanelModule,
  PickListModule,
  KnobModule,
  TagModule,
  InputGroupModule,
  TreeSelectModule,
  OrderListModule,
  TriStateCheckboxModule,
];

const OTHER_SHARED_MODULES = [NgxSpinnerModule];

const SHARED_MODULES = [
  ...INTERNAL_SHARED_MODULES,
  ...INTERNAL_SHARED_COMPONENTS,
  ...PRIME_NG_SHARED_MODULES,
  ...OTHER_SHARED_MODULES,
];

@NgModule({
  declarations: [],
  imports: [...ANGULAR_MODULES, ...SHARED_MODULES, PipesModule],
  exports: [
    ...ANGULAR_MODULES,
    ...SHARED_MODULES,
    PipesModule,
    DirectivesModule,
  ],
  providers: [PipesModule],
})
export class SharedGlobalModule {}
