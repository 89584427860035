import { BehaviorSubject } from 'rxjs';

export interface ILookupService<TResponse, T> {
  lookup$: BehaviorSubject<boolean>;

  fetchLookups(): Promise<void>;
  fetchLookupsByName(name: string): Promise<void>;
  findLookup?(lookupName: keyof TResponse, val: string | number): T | undefined;
  clearCache(): Promise<void>;
}

export enum LookupApi {
  Core = 'viacore',
  Integrate = 'viaintegrate',
  IAM = 'viaiam',
}

/**
 * @description Core Lookup Type
 */
export type Lookup = {
  id: number;
  name: string;
  code?: string;
};

export type IAMLookup = {
  id: number;
  name: string;
};

export type IntegrateLookup = {
  id: number;
  name: string;
};

export type LookupDetails = {
  id: number;
  name: string;
  code?: string;
  description?: string;
  isActive: boolean;
  createdDateTime: string;
};

export type ValueTypeInfo = {
  id: number;
  name: string;
  value: string;
  workstream: string;
};

export type Site = {
  id: number;
  name: string;
  code: string;
  stateCode: string;
};

export type County = Lookup & {
  stateId: number;
};

export type StepName = string;

export type StepStatuses = {
  id: number;
  stepName: string;
  status: string;
  isTrigger: boolean;
  isDefault: boolean;
  isMissingInfo: boolean;
};

export type MyStatuses = {
  id: number;
  statusName: string;
  isActive: boolean;
  patientEncounter: [];
};

export type MortgageType = LookupDetails & {
  isRequiredInvestorLoanNumber: boolean;
};

export type CoreLookupsResponse = {
  lienPriorityTypes: Lookup[];
  participantRoles: Lookup[];
  taxpayerIdentifierTypes: Lookup[];
  mortgageTypes: Lookup[];
  caseTypes: Lookup[];
  investorTypes: Lookup[];
  propertyTypes: Lookup[];
  propertyOccupancyTypes: Lookup[];
  states: Lookup[];
  noteTemplates: Lookup[];
  noteCategories: Lookup[];
  taskStatuses: Lookup[];
  participantTypes: Lookup[];
  documentTypes: Lookup[];
  investors: Lookup[];
  vendors: Lookup[];
  interestTypes: Lookup[];
  paymentTypes: Lookup[];
  foreclosureTypes: Lookup[];
  caseStatuses: Lookup[];
  chapterTypes: Lookup[];
  courts: Lookup[];
  countries: Lookup[];
  stepStatuses: Record<StepName, StepStatuses[]>;
  insurers: Lookup[];
  buildingPermitUsageStandardTypes: Lookup[];
  subTypes: Lookup[];
  partnerPlatforms: Lookup[];
  valueTypeInfos: ValueTypeInfo[];
};

export type IAMLookupsResponse = {
  tenantTypes: IAMLookup[];
  tenantStatuses: IAMLookup[];
  vendorTypes: IAMLookup[];
  roles: IAMLookup[];
  roleStatuses: IAMLookup[];
  userModules: IAMLookup[];
  sites: Site[];
  teams: IAMLookup[];
  languages: IAMLookup[];
  licenseCertificationTypes: IAMLookup[];
  userTimeZones: IAMLookup[];
  clients: IAMLookup[];
};

export type IntegrateLookupsResponse = {
  platformFrom: IntegrateLookup[];
  platformTo: IntegrateLookup[];
  from: IntegrateLookup[];
  to: IntegrateLookup[];
  statuses: IntegrateLookup[];
  transactionTypes: IntegrateLookup[];
};

export type LookupKeys =
  | keyof CoreLookupsResponse
  | keyof IntegrateLookupsResponse
  | keyof IAMLookupsResponse;
