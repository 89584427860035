export type GraphConnection = {
  source: string;
  target: string;
};

export class GraphNode<T> {
  data: T;
  // eslint-disable-next-line no-use-before-define
  children: GraphNode<T>[] = [];
  // eslint-disable-next-line no-use-before-define
  parent?: GraphNode<T>;

  constructor(nodeData: T) {
    this.data = nodeData;
    this.children = [];
  }

  addChild(child: GraphNode<T>) {
    child.parent = this;
    this.children.push(child);
  }
}

export class Graph<T extends { [key: string]: any }> {
  nodes: GraphNode<T>[] = [];
  root?: GraphNode<T>; // New property to store the root node

  constructor(
    nodeDataList: T[],
    connectionList: GraphConnection[],
    nodeIdentifierFunc: (nodeData: T) => string
  ) {
    const nodeMap: Map<string, GraphNode<T>> = new Map();

    for (const nodeData of nodeDataList) {
      const node = new GraphNode<T>(nodeData);

      const nodeIdentifier = nodeIdentifierFunc(nodeData);
      nodeMap.set(nodeIdentifier, node);
      this.nodes.push(node);
    }

    for (const connection of connectionList) {
      const sourceNode = nodeMap.get(connection.source);
      const targetNode = nodeMap.get(connection.target);

      if (sourceNode && targetNode) {
        sourceNode.addChild(targetNode);
      }
    }

    // Find and set the root node
    this.root = this.findRootNode();
  }

  private findRootNode(): GraphNode<T> | undefined {
    for (const node of this.nodes) {
      if (!node.parent) {
        return node;
      }
    }
    return undefined;
  }
}
