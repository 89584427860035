import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { LoggerService as logger } from '../../services/logger.service';

import { Stepper } from './stepper.component';

@Injectable({
  providedIn: 'any',
})
export class StepperService {
  private activeIndex!: number;
  private stepperData!: Stepper[];
  private maxStepCompleted = 0;
  private formData: any;

  constructor(private router: Router) {}

  get ActiveIndex() {
    return this.activeIndex;
  }

  set ActiveIndex(val: number) {
    this.activeIndex = val;
  }

  get StepperData() {
    return this.stepperData;
  }

  set StepperData(val: Stepper[]) {
    this.stepperData = val;
    this.formData = {};
    for (let i = 0; i < this.stepperData.length; i++) {
      this.formData[i] = {};
    }
  }

  set FormData(val: any) {
    this.formData[this.activeIndex] = val;
  }

  get FormData() {
    return this.formData[this.activeIndex];
  }

  get MaxStepCompleted() {
    return this.maxStepCompleted;
  }

  resetStepper() {
    this.activeIndex = 0;
    this.maxStepCompleted = 0;
    this.formData = {};
    this.stepperData = [];
  }

  fetchFormByIndex(idx: number) {
    if (idx >= 0 && idx < this.stepperData.length) {
      return this.formData[idx];
    }
    return;
  }

  removeStep(stepName: string) {
    const idx = this.stepperData.findIndex((step) => step.title === stepName);
    if (idx === -1) {
      logger.error(`Failed to remove step ${stepName}`);
      return;
    }
    this.stepperData.splice(idx, 1);
  }

  onNextClick(redirect = true) {
    if (this.stepperData.length - 1 == this.activeIndex) return;
    this.activeIndex++;
    if (this.maxStepCompleted < this.activeIndex) {
      this.maxStepCompleted = this.activeIndex;
    }

    if (redirect) {
      this.router.navigate([this.stepperData[this.activeIndex].routerLink]);
    }
  }

  onPreviousClick(redirect = true) {
    if (this.activeIndex == 0) return;
    this.activeIndex--;

    if (redirect) {
      this.router.navigate([this.stepperData[this.activeIndex].routerLink]);
    }
  }
}
