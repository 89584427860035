import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ellipsis',
})
export class EllipsisPipe implements PipeTransform {
  transform(value: string, maxLength: number, useSimple = true): string {
    if (value.length <= maxLength) return value;

    if (useSimple) {
      return `${value.slice(0, maxLength)}...`;
    }

    let i = maxLength;

    while (true) {
      if (value.charAt(i) === ' ' || i > value.length) break;
      i++;
    }

    return i <= value.length ? `${value.slice(0, i)}...` : value.slice(0, i);
  }
}
