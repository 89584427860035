/* eslint-disable spellcheck/spell-checker */
import {
  Component,
  // Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { LicenseManager } from 'ag-grid-enterprise';
import { PrimeNGConfig } from 'primeng/api';
import { Subject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

import { SidebarService } from './shared-global/modules/sidebar/sidebar.service';
import { CommonService } from './shared-global/services/common.service';

@Component({
  selector: 'outa-works-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  readonly spinnerTemplate = `
    <div>
      <span class="letters-flashing color-primary">
        <span>O</span>
        <span>O</span>
        <span>M</span>
        <span>C</span>
      </span>
      <div class="dot-flashing">
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
  `;
  private readonly _destroying$ = new Subject<void>();

  constructor(
    private primengConfig: PrimeNGConfig,
    private router: Router,
    private route: ActivatedRoute,
    private titleService: Title,
    // private authService: MsalService,

    // private msalBroadcastService: MsalBroadcastService,
    private commonService: CommonService,
    private sidebarService: SidebarService
    // @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration
  ) {
    this.primengConfig.ripple = true;
    LicenseManager.setLicenseKey(environment.AG_GRID_LICENSE_KEY);
  }

  ngOnInit() {
    this.router.events
      .pipe(filter((event: any) => event instanceof NavigationEnd))
      .subscribe(() => {
        const rt = this.commonService.getActivatedRouteChild(this.route);

        rt.data.subscribe((data: any) => {
          this.titleService.setTitle(data?.['title'] || '');
        });
        this.sidebarService.getActiveMenu();
      });
  }

  ngOnDestroy() {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }

  /**
   * If no active account set but there are accounts signed in, sets first account to active account
   * To use active account set here, subscribe to inProgress$ first in your component
   * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
   */
}
