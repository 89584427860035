<div class="grid-center h-full">
  <div class="flex flex-column justify-content-center gap-3">
    <img
      src="assets/images/page-not-found.svg"
      alt="page-not-found"
      width="150"
      height="150"
    />
    <span class="text-3xl font-bold color-primary">Page Not Found</span>
    <span class="text-2xl font-medium color-primary">
      The requested
      <span
        class="url font-bold text-underline cursor-pointer"
        [pTooltip]="commonService.getUrlWithoutParams()"
        tooltipPosition="top"
        >URL</span
      >
      was not found on this server.
    </span>
    <div class="flex justify-content-center gap-2">
      <button
        pButton
        pRipple
        type="button"
        icon="pi pi-arrow-left"
        iconPos="left"
        label="Back"
        (click)="back()"
      ></button>
      <button
        pButton
        pRipple
        type="button"
        icon="pi pi-home"
        iconPos="left"
        label="Home"
        routerLink="/"
      ></button>
    </div>
  </div>
</div>
