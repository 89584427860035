import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class StringUtils {
  camelCaseToSpacePascalCase(input: string): string {
    // Split the string by capital letters
    const words = input.replace(/([a-z])([A-Z])/g, '$1 $2').split(/\s+/);

    // Capitalize the first letter of each word
    const capitalizedWords = words.map(
      (word) => word.charAt(0).toUpperCase() + word.slice(1)
    );

    // Join the words with spaces
    return capitalizedWords.join(' ');
  }
}
