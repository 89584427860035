import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { BadgeModule } from 'primeng/badge';
import { TooltipModule } from 'primeng/tooltip';

@Component({
  selector: 'outa-works-grid-badge',
  imports: [CommonModule, BadgeModule, TooltipModule],
  standalone: true,
  template: `
    <p-badge
      class="white-space-nowrap flex"
      tooltipPosition="top"
      [pTooltip]="params.status"
      [ngClass]="params.statusClass"
    >
    </p-badge>
    <i
      *ngIf="params.isUrgent"
      tooltipPosition="top"
      pTooltip="Urgent Order"
      class="pi pi-flag-fill color-red ml-2 text-xl"
    ></i>
  `,
})
export class GridBadgeComponent implements ICellRendererAngularComp {
  params: any;

  agInit(params: ICellRendererParams) {
    this.params = params;
  }

  refresh() {
    return false;
  }
}
