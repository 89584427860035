import {
  computed,
  effect,
  Injectable,
  signal,
  WritableSignal,
} from '@angular/core';
import { AbilityBuilder, AbilityClass, PureAbility } from '@casl/ability';
import {
  AppAbility,
  PermissionAction,
  PermissionSubject,
  ProfileType,
  UserDetails,
} from '@outa-works/models';
import { BehaviorSubject } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';

// const userDetails: UserDetails = {
//   id: 123,
//   uniqueId: 'user123',
//   email: 'user@example.com',
//   firstName: 'John',
//   middleName: null,
//   lastName: 'Doe',
//   isActive: true,
//   currentTenantId: 'tenant1',
//   accessibleTenants: [
//     {
//       tenantId: 'tenant1',
//       name: 'Tenant 1',
//       isCurrentTenant: true,
//       tenantType: 'Type1',
//       tenantTypeId: 1,
//     },
//     {
//       tenantId: 'tenant2',
//       name: 'Tenant 2',
//       isCurrentTenant: false,
//       tenantType: 'Type2',
//       tenantTypeId: 2,
//     },
//   ],
//   userRoles: [
//     {
//       roleId: 1,
//       userId: 123,
//       roleName: 'Admin',
//       permissions: [
//         {
//           id: 19,
//           name: 'viaCMS|Case Search|Create',
//           code: `${PermissionSubject.Case}.${PermissionAction.Create}`,
//         },
//         {
//           id: 1,
//           name: 'viaCMS|Case Search|Read',
//           code: `${PermissionSubject.Case}.${PermissionAction.Read}`,
//         },
//         {
//           id: 2,
//           name: 'viaCMS|My Work Queue|Read',
//           code: `${PermissionSubject.MyWorkQueue}.${PermissionAction.Read}`,
//         },
//         {
//           id: 3,
//           name: 'viaCMS|My Tasks|Read',
//           code: `${PermissionSubject.MyTasks}.${PermissionAction.Read}`,
//         },
//         {
//           id: 4,
//           name: 'viaCMS|Referral Intake|Read',
//           code: `${PermissionSubject.ReferralIntake}.${PermissionAction.Read}`,
//         },
//         {
//           id: 5,
//           name: 'viaCMS|Case Participants|Read',
//           code: `${PermissionSubject.Participants}.${PermissionAction.Read}`,
//         },
//         {
//           id: 6,
//           name: 'viaCMS|Case|Case Steps|Read',
//           code: `${PermissionSubject.CaseSteps}.${PermissionAction.Read}`,
//         },
//         {
//           id: 7,
//           name: 'viaCMS|Case|Notes|Read',
//           code: `${PermissionSubject.CaseNotes}.${PermissionAction.Read}`,
//         },
//         {
//           id: 8,
//           name: 'viaCMS|Case|Documents|Read',
//           code: `${PermissionSubject.CaseDocuments}.${PermissionAction.Read}`,
//         },
//         {
//           id: 9,
//           name: 'viaCMS|Case|Tasks|Read',
//           code: `${PermissionSubject.CaseTasks}.${PermissionAction.Read}`,
//         },
//         {
//           id: 9,
//           name: 'viaCMS|Case|Chronology|Read',
//           code: `${PermissionSubject.CaseChronology}.${PermissionAction.Read}`,
//         },
//         {
//           id: 10,
//           name: 'viaCMS|Case|Invoice History|Read',
//           code: `${PermissionSubject.CaseInvoiceHistory}.${PermissionAction.Read}`,
//         },
//         {
//           id: 11,
//           name: 'viaCMS|Case|Messages|Read',
//           code: `${PermissionSubject.CaseMessages}.${PermissionAction.Read}`,
//         },
//         {
//           id: 12,
//           name: 'viaCMS|Case|Hold|Read',
//           code: `${PermissionSubject.CaseHold}.${PermissionAction.Read}`,
//         },
//         {
//           id: 13,
//           name: 'viaCMS|Case|DocGen|Read',
//           code: `${PermissionSubject.CaseDocGen}.${PermissionAction.Read}`,
//         },
//         {
//           id: 14,
//           name: 'viaCMS|Case|Participants|Read',
//           code: `${PermissionSubject.CaseParticipants}.${PermissionAction.Read}`,
//         },
//         {
//           id: 15,
//           name: 'viaCMS|Case|Reforecast',
//           code: `${PermissionSubject.Case}.${PermissionAction.Reforecast}`,
//         },
//         {
//           id: 16,
//           name: 'viaCMS|Case|Notes|Create',
//           code: `${PermissionSubject.CaseNotes}.${PermissionAction.Create}`,
//         },
//         {
//           id: 17,
//           name: 'viaCMS|Case|Notes|Update',
//           code: `${PermissionSubject.CaseNotes}.${PermissionAction.Update}`,
//         },
//         {
//           id: 18,
//           name: 'viaCMS|Case|Notes|Delete',
//           code: `${PermissionSubject.CaseNotes}.${PermissionAction.Delete}`,
//         },
//       ],
//     },
//   ],
//   modules: [
//     {
//       id: 1,
//       name: 'viaCore',
//       code: PermissionSubject.viaCore,
//     },
//     {
//       id: 2,
//       name: 'viaWorks',
//       code: PermissionSubject.viaWorks,
//     },
//     {
//       id: 3,
//       name: 'viaAdmin',
//       code: PermissionSubject.viaAdmin,
//     },
//     {
//       id: 4,
//       name: 'viaMarketplace',
//       code: PermissionSubject.viaMarketplace,
//     },
//     {
//       id: 5,
//       name: 'viaPeople',
//       code: PermissionSubject.viaPeople,
//     },
//     {
//       id: 6,
//       name: 'viaDocGen',
//       code: PermissionSubject.viaDocGen,
//     },
//     {
//       id: 7,
//       name: 'viaMoney',
//       code: PermissionSubject.viaMoney,
//     },
//   ],
// };

@Injectable({
  providedIn: 'root',
})
export class UserDataService {
  private _userDetails: WritableSignal<UserDetails | null> = signal(null);
  private _userProfile!: ProfileType;
  private _userAbilities: WritableSignal<AppAbility | null> = signal(null);
  private userAbilitiesSubject = new BehaviorSubject<AppAbility | null>(null);

  public userAbilities = computed(() => this._userAbilities());
  public userAbilities$ = this.userAbilitiesSubject.asObservable();
  public userDetails = computed(() => this._userDetails());
  public isCurrentTenantA360 = computed(() => {
    const currentTenant = this._userDetails()?.accessibleTenants.find(
      (t) => t.isCurrentTenant
    );
    return currentTenant?.name == 'a360 Inc';
  });

  constructor(private apiService: ApiService) {
    effect(
      () => {
        this.calculateUserAbilities();
      },
      { allowSignalWrites: true }
    );
  }

  get UserProfileData(): ProfileType {
    return this._userProfile;
  }

  set UserProfileData(userProfile: ProfileType) {
    this._userProfile = userProfile;
  }

  get PermissionAction() {
    return PermissionAction;
  }

  get PermissionSubject() {
    return PermissionSubject;
  }

  async fetchLoggedInUserDetails() {
    const userDetails = (await this.apiService.get(
      'viaiam/api/user/me'
    )) as UserDetails;
    this._userDetails.set(userDetails);
  }

  calculateUserAbilities() {
    const { can, build } = new AbilityBuilder(
      PureAbility as AbilityClass<AppAbility>
    );

    for (const module of this._userDetails()?.modules || []) {
      can(PermissionAction.Read, module.code);
    }
    for (const role of this._userDetails()?.userRoles || []) {
      for (const permission of role.permissions) {
        const splitPermissionCode = permission.code.split('.');
        const action = splitPermissionCode.pop() as PermissionAction;
        const subject = splitPermissionCode.join('.') as PermissionSubject;
        can(action, subject);
      }
    }

    const permissions = build();
    permissions.can = () => true;
    this._userAbilities.set(permissions);
    this.userAbilitiesSubject.next(permissions);
  }

  getUserDetails() {
    if (!this._userDetails()) {
      // await this.fetchLoggedInUserDetails();
    }
    return this._userDetails();
  }
}
