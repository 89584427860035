import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { InputSwitchModule } from 'primeng/inputswitch';
import { SelectButtonModule } from 'primeng/selectbutton';

@Component({
  selector: 'outa-works-grid-switch',
  imports: [CommonModule, FormsModule, InputSwitchModule, SelectButtonModule],
  standalone: true,
  template: `
    <p-inputSwitch
      class="flex"
      [ngModel]="params.data[params.colDef.field]"
      (onChange)="params.onChange($event, params.data)"
    >
    </p-inputSwitch>
  `,
})
export class GridSwitchComponent implements ICellRendererAngularComp {
  params!: any;

  agInit(params: ICellRendererParams) {
    this.params = params;
  }

  refresh() {
    return false;
  }
}
