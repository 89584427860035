import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'outa-works-grid-link',
  imports: [CommonModule, RouterModule],
  standalone: true,
  template: `
    <span *ngIf="params.disableLink">
      {{ params.label }}
    </span>
    <span *ngIf="!params.disableLink">
      <a
        *ngIf="!params.onClick"
        class="color-primary"
        [routerLink]="params.routerLink"
        [queryParams]="params.queryParams"
      >
        {{ params.label }}
      </a>
      <a
        *ngIf="params.onClick"
        class="color-primary cursor-pointer"
        (click)="params.onClick?.($event)"
      >
        {{ params.label }}
      </a>
    </span>
  `,
})
export class GridLinkComponent implements ICellRendererAngularComp {
  @Input() params!: any;

  agInit(params: ICellRendererParams) {
    this.params = params;
  }

  refresh() {
    return false;
  }
}
