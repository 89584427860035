import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
} from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from '@outa-works/services';
import { MenuItem } from 'primeng/api';

export class OBasePanelMenuItem {
  constructor(private ref: ChangeDetectorRef) {}
  handleClick(event: any, item: any) {
    if (item.disabled) {
      event.preventDefault();
      return;
    }

    item.expanded = !item.expanded;
    this.ref.detectChanges();

    if (!item.url && !item.routerLink) {
      event.preventDefault();
    }

    if (item.command) {
      item.command({
        originalEvent: event,
        item: item,
      });
    }
  }
}

@Component({
  selector: 'outa-works-panel-menu',
  templateUrl: './panelmenu.component.html',
  animations: [
    trigger('rootItem', [
      state(
        'hidden',
        style({
          height: '0',
        })
      ),
      state(
        'visible',
        style({
          height: '*',
        })
      ),
      transition('visible <=> hidden', [animate('{{transitionParams}}')]),
      transition('void => *', animate(0)),
    ]),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./panelmenu.component.scss'],
})
export class OPanelMenuComponent extends OBasePanelMenuItem {
  @Input() menuItem!: any;
  @Input() openedState!: boolean;
  @Output() sideBarCollapsed = new EventEmitter<boolean>();
  @HostBinding('class') class = 'p-element';

  public animating!: boolean;
  selectedSubMenuItem: any;
  constructor(
    ref: ChangeDetectorRef,
    private router: Router,
    public commonService: CommonService
  ) {
    super(ref);
  }
  public get opened(): boolean {
    return this.openedState;
  }
  visible(item: MenuItem) {
    return item.visible !== false;
  }
  collapseSideBar() {
    this.sideBarCollapsed.next(false);
  }
  onSubMenuItemClick(event: any) {
    if (event.option?.routerLink && event.option?.target) {
      return window.open(event.option.routerLink, event.option.target);
    }
    this.router.navigate([event.option.routerLink]);
    // location.reload();
    return this.collapseSideBar();
  }
}
