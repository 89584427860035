import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'displaySpace',
})
export class DisplaySpacePipe implements PipeTransform {
  transform(value: string) {
    return value.replace(/^\s+|\s+$/gi, '•');
  }
}
