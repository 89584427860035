import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
// import { MsalService } from '@azure/msal-angular';
import { ProfileType } from '@outa-works/models';
import { CommonService } from '@outa-works/services';
import { SelectButtonOptionClickEvent } from 'primeng/selectbutton';
import { AuthService } from 'src/app/services/auth.service';

import { UserDataService } from '../../services/user-data.service';

import { SidebarService } from './sidebar.service';

@Component({
  selector: 'outa-works-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  private isOpen = true;
  profile: ProfileType = {};
  public isDockableSidebarOpened = false;

  public get opened(): boolean {
    return this.isOpen;
  }

  constructor(
    private authService: AuthService,
    public commonService: CommonService,
    private router: Router,
    private userDataService: UserDataService,
    public sidebarService: SidebarService
  ) {}

  ngOnInit() {
    this.getProfile();
  }

  onDockableSideBarCollapse() {
    this.sidebarService.selectedMenu.expanded = false;
    this.isDockableSidebarOpened = false;
  }

  getShortName(fullName?: string) {
    return fullName
      ?.split('.')
      .map((n) => n[0])
      .join('')
      .toLocaleUpperCase();
  }

  getProfile() {
    this.authService.Authenticated().subscribe((userinfo) => {
      this.profile.displayName = userinfo.userName || '';
      //this.profile.id = idTokenClaims.oid || '';
      this.profile.userPrincipalName = userinfo.userName || '';
      //this.profile.outaWorksUserId = Number(idTokenClaims['viaUserId']) || 0;
      if (this.profile.displayName.split('\\').length > 1) {
        this.profile.shortName = this.getShortName(
          this.profile.displayName?.split('\\')[1]
        );
      } else {
        this.profile.shortName = this.getShortName(this.profile.displayName);
      }

      this.userDataService.UserProfileData = this.profile;
    }); // Assuming you have a single account
    //const idTokenClaims = user?.idTokenClaims || {};
  }

  openDockableSideBar(event: SelectButtonOptionClickEvent) {
    if (event.index || event.index === 0) {
      this.sidebarService.selectedMenu =
        this.commonService.getActivatedMenuItems(
          this.sidebarService.menuItems()
        )[event.index];
    }
    if (event.option.command) {
      event.option.command(event);
      return;
    }
    this.sidebarService.menuItems().forEach((item: any) => {
      if (item != this.sidebarService.selectedMenu) {
        item.expanded = false;
      }
    });
    if (this.sidebarService.selectedMenu.items) {
      if (this.sidebarService.selectedMenu.expanded) {
        this.sidebarService.selectedMenu.expanded = false;
        this.isDockableSidebarOpened = false;
      } else {
        this.sidebarService.selectedMenu.expanded = true;
        this.isDockableSidebarOpened = true;
      }
    } else {
      this.isDockableSidebarOpened = false;
      if (this.sidebarService.selectedMenu.routerLink) {
        this.router.navigate([this.sidebarService.selectedMenu.routerLink], {
          state: { navId: 0 },
        });
      }
      this.sidebarService.selectedMenu.expanded = false;
    }
  }

  gotoDashboard() {
    this.router.navigate(['/']);
  }
}
