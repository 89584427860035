import {
  HttpClient,
  HttpContext,
  HttpHeaders,
  HttpParams,
} from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { lastValueFrom } from 'rxjs';

import { CommonService } from '../shared-global/services/common.service';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  get BASE_URL() {
    return this.baseUrl;
  }

  constructor(
    private http: HttpClient,
    private commonService: CommonService,
    private spinner: NgxSpinnerService,
    @Inject('BASE_URL') private baseUrl: string
  ) {}

  private errorHandler(err: any) {
    const severity = err.status === 500 ? 'Warn' : 'Error';
    const errors = Object.values<string>(err.error?.errors || { _: [''] });

    for (const errs of errors) {
      for (const err of errs || ['']) {
        this.commonService.showToast(
          severity,
          err || 'An error occurred. Please try again later.',
          'Error'
        );
      }
    }
    throw err;
  }

  get<T>(
    apiRoute: string,
    options?: {
      headers?:
        | HttpHeaders
        | {
            [header: string]: string | string[];
          };
      context?: HttpContext;
      observe?: 'body' | 'response';
      params?:
        | HttpParams
        | {
            [param: string]:
              | string
              | number
              | boolean
              | ReadonlyArray<string | number | boolean>;
          };
      reportProgress?: boolean;
      responseType?: 'arraybuffer' | 'blob' | 'json' | 'text';
      withCredentials?: boolean;
    }
  ) {
    this.spinner.show();

    return lastValueFrom(
      this.http.get(`${this.baseUrl}${apiRoute}`, options as any)
    )
      .catch((err) => this.errorHandler(err))
      .finally(() => this.spinner.hide()) as Promise<T>;
  }

  post<T>(
    apiRoute: string,
    body: any,
    options?: {
      headers?:
        | HttpHeaders
        | {
            [header: string]: string | string[];
          };
      context?: HttpContext;
      observe?: 'body';
      params?:
        | HttpParams
        | {
            [param: string]:
              | string
              | number
              | boolean
              | ReadonlyArray<string | number | boolean>;
          };
      reportProgress?: boolean;
      responseType?: 'blob' | 'json';
      withCredentials?: boolean;
    }
  ) {
    this.spinner.show();

    return lastValueFrom(
      this.http.post(`${this.baseUrl}${apiRoute}`, body, options as any)
    )
      .catch((err) => this.errorHandler(err))
      .finally(() => this.spinner.hide()) as Promise<T>;
  }

  put<T>(
    apiRoute: string,
    body: any,
    options?: {
      headers?:
        | HttpHeaders
        | {
            [header: string]: string | string[];
          };
      context?: HttpContext;
      observe?: 'body';
      params?:
        | HttpParams
        | {
            [param: string]:
              | string
              | number
              | boolean
              | ReadonlyArray<string | number | boolean>;
          };
      reportProgress?: boolean;
      responseType?: 'json';
      withCredentials?: boolean;
    }
  ) {
    this.spinner.show();
    return lastValueFrom(
      this.http.put(`${this.baseUrl}${apiRoute}`, body, options)
    )
      .catch((err) => this.errorHandler(err))
      .finally(() => this.spinner.hide()) as Promise<T>;
  }

  patch<T>(
    apiRoute: string,
    body: any,
    options?: {
      headers?:
        | HttpHeaders
        | {
            [header: string]: string | string[];
          };
      context?: HttpContext;
      observe?: 'body';
      params?:
        | HttpParams
        | {
            [param: string]:
              | string
              | number
              | boolean
              | ReadonlyArray<string | number | boolean>;
          };
      reportProgress?: boolean;
      responseType?: 'json';
      withCredentials?: boolean;
    }
  ) {
    this.spinner.show();
    return lastValueFrom(
      this.http.patch(`${this.baseUrl}${apiRoute}`, body, options)
    )
      .catch((err) => this.errorHandler(err))
      .finally(() => this.spinner.hide()) as Promise<T>;
  }

  delete<T>(
    apiRoute: string,
    options?: {
      headers?:
        | HttpHeaders
        | {
            [header: string]: string | string[];
          };
      context?: HttpContext;
      observe?: 'body';
      params?:
        | HttpParams
        | {
            [param: string]:
              | string
              | number
              | boolean
              | ReadonlyArray<string | number | boolean>;
          };
      reportProgress?: boolean;
      responseType?: 'json';
      withCredentials?: boolean;
    }
  ) {
    this.spinner.show();

    return lastValueFrom(
      this.http.delete(`${this.baseUrl}${apiRoute}`, options)
    )
      .catch((err) => this.errorHandler(err))
      .finally(() => this.spinner.hide()) as Promise<T>;
  }
}
