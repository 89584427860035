<tr>
  <ng-container *ngFor="let cols of tableColumns">
    <th *ngIf="cols.isSortable; else noSort" [pSortableColumn]="cols.field">
      {{ cols.header }}
      <p-sortIcon [field]="cols.field"></p-sortIcon>
    </th>
    <ng-template #noSort>
      <th>
        {{ cols.header }}
      </th>
    </ng-template>
  </ng-container>
</tr>
