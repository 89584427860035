<div class="flex h-full">
  <aside
    aria-label="Dockable Sidebar"
    class="sidebar dockable-sidebar--{{ opened ? 'opened' : 'closed' }}"
    role="complementary"
  >
    <span
      class="mt-2 flex justify-content-center cursor-pointer"
      (click)="gotoDashboard()"
    >
      <span class="logo font-bold text-sm text-white"> OOMC </span>
    </span>
    <span
      class="flex justify-content-center w-full py-2"
      [pTooltip]="profile.displayName"
      tooltipPosition="right"
    >
      <p-avatar
        [label]="profile.shortName"
        size="normal"
        shape="circle"
      ></p-avatar>
    </span>
    <div class="text-center">
      <p-selectButton
        [options]="
          commonService.getActivatedMenuItems(sidebarService.menuItems())
        "
        [ngModel]="sidebarService.selectedMenu"
        (onOptionClick)="openDockableSideBar($event)"
        id="menu-item"
      >
        <ng-template pTemplate let-item>
          <i
            *ngIf="!item.url"
            [ngClass]="item.icon"
            [pTooltip]="item.tooltipOptions.tooltipLabel"
            tooltipPosition="right"
            tooltipStyleClass="ml-3"
          ></i>
        </ng-template>
      </p-selectButton>
    </div>
  </aside>
  <outa-works-panel-menu
    (sideBarCollapsed)="onDockableSideBarCollapse()"
    [openedState]="isDockableSidebarOpened"
    [menuItem]="sidebarService.selectedMenu"
  ></outa-works-panel-menu>
</div>
